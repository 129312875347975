import React from 'react';
import './App.css';
import PageLayout from './components/layout';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";

function App() {
  const isMobileDevice = /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent);

  if (isMobileDevice) {
    const currentPath = window.location.pathname + window.location.search + window.location.hash;
    window.location.href = `https://m.studioq.co.in${currentPath}`;
  }

  return (
    <>
  <PageLayout />

  </>
);
}

export default App;
