import React, { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom"
import bannerImg from '../../../assets/images/banner-img.svg';
import ShuffleTextEffect from "../../../components/text-ani";
import * as motion from "motion/react-client"
import { FadeIn } from "../../../components/animations";
import Slider from "react-slick";
import Button from "../../../components/ui/button";



const HomeBannerSection = () => {
    const navigate = useNavigate();
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
    };

    return (
        <div className='w-full'>
            <div className='container'>
                <div class="grid gap-4 grid-cols-12">
                    <div className='col-span-8'>
                        <div className="flex flex-col items-start text-left">
                            <FadeIn tag="h1"
                                className="font-regular  text-[33px] text-black leading-[44px]">
                                Elevating User Interactions <br />with{" "}

                                <ShuffleTextEffect className='text-black text-[46px] font-[Nyght] font-extrabold italic relative bg-[#FFD600] w-[238px] overflow-hidden inline-grid text-nowrap h-[44px]' text="Innovative" />
                                {" "}
                                <span className="solution-line">Solutions</span>
                            </FadeIn>
                            <FadeIn delay={.5} tag="p" className="mt-2 text-[18px] text-[#2C2421] font-light font-montserrat">
                                <span className="font-semibold">17 Years of Excellence</span> in Branding, <br />Web & Mobile App Design & Development
                            </FadeIn>
                        </div>
                        <FadeIn tag="div" className='w-full mt-[40px] pr-[65px]'>
                            <img src={bannerImg} className="App-logo" alt="logo" />
                        </FadeIn>

                    </div>
                    <div className='col-span-4'>
                        <div className="flex flex-col items-center text-left">
                            <FadeIn className="text-black text-[23px] leading-[25px] font-[Nyght] italic font-extrabold text-center">
                                Built on <ShuffleTextEffect className='bg-[#FFD600] w-[75px] overflow-hidden inline-grid text-nowrap h-[24px]' text="Trust," /> <br /> Elevated by Results
                            </FadeIn>
                            <FadeIn delay={.5} className="text-black text-[14px] leading-[18px] font-regular text-center mt-4">
                                Trusted by Industry Leaders <br />
                                and Growing Startups
                            </FadeIn>

                            <Slider {...settings} className="w-full">
                                <div>
                                    <div class="grid gap-4 grid-cols-2 mt-7">
                                        <div className='flex justify-center'>
                                            <img src='/assets/images/govt-pondy.svg' className="py-[25px]" alt="Government of Puducherry" />
                                        </div>
                                        <div className='flex justify-center'>
                                            <img src='/assets/images/kalakshetra.svg' className="py-[25px]" alt="Kalakshetra" />
                                        </div>

                                        <div className='flex justify-center'>
                                            <img src='/assets/images/hidesign.svg' className="py-[25px]" alt="Hidesign" />
                                        </div>

                                        <div className='flex justify-center'>
                                            <img src='/assets/images/tata.svg' className="py-[25px]" alt="Tata" />
                                        </div>


                                        <div className='flex justify-center'>
                                            <img src='/assets/images/mercury.svg' className="py-[20px]" alt="Mercury" />
                                        </div>

                                        <div className='flex justify-center'>
                                            <img src='/assets/images/wolters.svg' className="py-[25px]" alt="wolters" />
                                        </div>

                                    </div>
                                </div>
                                <div>
                                    <div class="grid gap-4 grid-cols-2 mt-7">
                                        <div className='flex justify-center'>
                                            <img src='/assets/images/one-foundation.svg' className="py-[25px]" alt="one-foundation" />
                                        </div>
                                        <div className='flex justify-center'>
                                            <img src='/assets/images/part-timer.svg' className="py-[25px]" alt="part-timer" />
                                        </div>

                                        <div className='flex justify-center'>
                                            <img src='/assets/images/truFit.svg' className="py-[25px]" alt="truFit" />
                                        </div>

                                        <div className='flex justify-center'>
                                            <img src='/assets/images/avaikalam.svg' className="py-[25px]" alt="avaikalam" />
                                        </div>

                                        <div className='flex justify-center'>
                                            <img src='/assets/images/kubeSafe.svg' className="py-[25px]" alt="kubeSafe" />
                                        </div>

                                        <div className='flex justify-center'>
                                            <img src='/assets/images/hopsticks.svg' className="py-[25px]" alt="hopsticks" />
                                        </div>

                                    </div>
                                </div>
                            </Slider>
                            <div className="text-black text-[14px] leading-[17px] font-regular text-center mt-4">
                                Have a vision for greatness? <br />
                                Let’s build something extraordinary together!
                            </div>
                            <Button type="filled" className="!mt-[17px] w-[80%]" onClick={() => navigate("/contact-us")}>
                                Start Your Project Now
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
};

export default HomeBannerSection;