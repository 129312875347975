import React from 'react';
import { Routes, Route } from 'react-router-dom'
import Slider from "react-slick";
import ShuffleTextEffect from '../../components/text-ani';
import { Helmet } from 'react-helmet';



const WhyUsScreen = () => {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        // fade: true,
        nextArrow: <img src='/assets/images/next.svg' className="w-[28px] h-[28px]" alt="next" />,
        prevArrow: <img src='/assets/images/prev.svg' className="w-[28px] h-[28px]" alt="prev" />
      };
    return (
        <div className="w-full mb-[40px]">
            <Helmet>
                <title>Why Choose StudioQ? 17+ Years of Expertise in Design & Development</title>
            </Helmet>
            <div className="container">
                <div className="grid gap-[50px] gap-y-[35px] grid-cols-12">
                    <div className="col-span-8">
                        <div className="grid gap-[20px] gap-y-[35px] grid-cols-12">
                            <div className="col-span-5">
                                <h1 className="font-regular  text-[33px] text-black leading-tight">
                                    Why We’re Your  <br />
                                    <ShuffleTextEffect className="text-black text-[39px] font-[Nyght] font-extrabold italic relative bg-[#FFD600]" text="Perfect Partner" />
                                </h1>
                                <p className="mt-[18px] text-[18px] text-[#2C2421] font-light font-montserrat">
                                    Because your vision deserves creativity, precision, and a team that truly cares.
                                </p>
                                <div className="grid grid-cols-1 sm:grid-cols-2 gap-[42px] justify-between mt-[30px]">
                                    <div className='flex flex-col items-start'>
                                        <h1 className='text-[33px] text-[#000000] font-bold font-Nyght'>17</h1>
                                        <h3 className="text-[13px] text-[#2C2421] font-montserrat leading-[17px]">Years of experience in design and development.</h3>
                                    </div>
                                    <div className='flex flex-col items-start'>
                                        <h1 className='text-[33px] text-[#000000] font-bold font-Nyght'>100+</h1>
                                        <h3 className="text-[13px] text-[#2C2421] font-montserrat leading-[17px]">Projects delivered across diverse industries.</h3>
                                    </div>
                                    <div className='flex flex-col items-start'>
                                        <h1 className='text-[33px] text-[#000000] font-bold font-Nyght'>10+</h1>
                                        <h3 className="text-[13px] text-[#2C2421] font-montserrat leading-[17px]">Clients served globally, building lasting partnerships.</h3>
                                    </div>
                                    <div className='flex flex-col items-start'>
                                        <h1 className='text-[33px] text-[#000000] font-bold font-Nyght'>25+</h1>
                                        <h3 className="text-[13px] text-[#2C2421] font-montserrat leading-[17px]">Dedicated professionals committed to turning ideas into reality.</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-7">
                                <img src='/assets/images/about-us-vector.svg' className="w-full" alt="ProductIcon" />
                            </div>
                        </div>
                        <div className='w-full gap-[30px]'>
                            <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px] mt-[50px]">Whom We Work With</h3>
                            <div className=" text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[20px]">
                                <p className='py-[10px]'>
                                    <b>Startups:</b> We partner with ambitious startups, helping them shape their ideas into tangible products. Whether it's designing an MVP or scaling a product, we provide tailored solutions to meet their evolving needs.
                                </p>
                                <p className='py-[10px]'>
                                    <b>Small & Medium Businesses (SMBs):</b> We understand the challenges faced by growing businesses and offer scalable design and development services that align with their goals and budget.
                                </p>
                                <p className='py-[10px]'>
                                    <b>Global Enterprises:</b> For large-scale businesses, we deliver robust, high-performance digital solutions that enhance operational efficiency and user engagement.
                                </p>
                                <p className='py-[10px]'>
                                    <b>Agencies & Consultants:</b> We extend our expertise to agencies and consultants, acting as their trusted design and development partners to execute projects seamlessly.
                                </p>
                                <p className='py-[10px]'>
                                    <b>Nonprofits & Cultural Institutions: </b> We take pride in supporting organizations that make a difference, designing meaningful digital experiences that amplify their mission and impact
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-span-4">
                        <div className='w-full gap-[30px]'>
                            <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px] mt-[90px]">Why us?</h3>
                            <div className=" text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] ">
                                <p className='py-[10px]'>
                                    At StudioQ, we are passionate about crafting meaningful digital experiences that leave a lasting impact. As a remote design agency based in India, we collaborate with clients across the globe, seamlessly blending creativity, strategy, and cutting-edge technology to deliver solutions that truly stand out.
                                </p>
                                <p className='py-[10px]'>
                                    Our approach is rooted in understanding the unique needs of each project, ensuring every design not only looks stunning but also performs exceptionally.
                                </p>
                                <p className='py-[10px]'>
                                    <b>Easy to Use:</b>  Prioritizing intuitive designs that users love.
                                </p>
                                <p className='py-[10px]'>
                                    <b>Accessible:</b>  Ensuring inclusivity for all
                                </p>
                                <p className='py-[10px]'>
                                    <b>Engaging: </b> Driving interaction through thoughtful design.
                                </p>
                                <p className='py-[10px]'>
                                    <b>Delightful: </b> Leaving lasting impressions on users.
                                </p>
                            </div>
                        </div>

                        <div className="border border-[#000] py-[27px] px-[27px] w-full mt-[30px]">
                            <h3 className="text-[22px] leading-[44px] font-regular text-[#2C2421]">What Our Clients Say</h3>
                            <Slider {...settings} className="clientSlider">
                                <div className="relative pb-[15px] text-[13px] text-[#2C2421] font-regular font-instrument leading-[24px]">
                                    {/* <img src='/assets/images/cook-along.svg' className="mb-[10px] mt-[16px]" alt="ProductIcon" /> */}
                                    <h3 className="">StudioQ has been a long associate of Feel-Inde in improving web developments, analytics and seo. Always energetic & focussed team is the one which I love in StudioQ. We started together and hope the relay continues to next generation.</h3>
                                    <div className="font-bold mt-[15px]">- Narayanan Ponnambalam</div>
                                    <div className="">Founder at SARL FEEL-INDE</div>
                                    <img src='/assets/images/quot.svg' className="absolute top-0 right-0 z-[-1]" alt="ProductIcon" />
                                </div>

                                <div className="relative pb-[15px] text-[13px] text-[#2C2421] font-regular font-instrument leading-[24px]">
                                    <h3 className="">For over 15 years, I’ve partnered with Studio Q on various web projects. Their expertise spans from early HTML and PHP to modern full-stack apps. They consistently deliver high-quality work on time, demonstrating dedication throughout our collaboration.</h3>
                                    <div className="font-bold mt-[15px]">- Krishna Prathab R V</div>
                                    <div className="">Founder & CEO at Hopsticks</div>
                                    <img src='/assets/images/quot.svg' className="absolute top-0 right-0 z-[-1]" alt="ProductIcon" />
                                </div>

                                <div className="relative pb-[15px] text-[13px] text-[#2C2421] font-regular font-instrument leading-[24px]">
                                    <h3 className="">I’ve had the pleasure of working with StudioQ on multiple projects, and their services have truly transformed our operations. Their dedication, insight, and technical skills stand out in every interaction.</h3>
                                    <div className="font-bold mt-[15px]">- Anand Balakrishnan</div>
                                    <div className="">Founder at Cerebral-Works</div>
                                    <img src='/assets/images/quot.svg' className="absolute top-0 right-0 z-[-1]" alt="ProductIcon" />
                                </div>

                                <div className="relative pb-[15px] text-[13px] text-[#2C2421] font-regular font-instrument leading-[24px]">
                                    <h3 className="">StudioQ has been our trusted partner for 14+ years, consistently delivering innovative solutions that drive our growth. Their reliability and dedication make them invaluable, and we look forward to many more years of collaboration!</h3>
                                    <div className="font-bold mt-[15px]">- Fayaz Ahamad</div>
                                    <div className="">Founder, Yalmaa Digital Agency</div>
                                    <img src='/assets/images/quot.svg' className="absolute top-0 right-0 z-[-1]" alt="ProductIcon" />
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyUsScreen;