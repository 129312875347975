import PageRoutes from "../pages/routes";
import Footer from "./footer";
import Header from "./header";



const PageLayout = () => {
    return (
     <>
     <Header/>
     <PageRoutes/>
     <Footer/>
     </>
    )
  };
  
  export default PageLayout;