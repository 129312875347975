import React, { useEffect, useState } from "react";
import './style.css'


export default function LoaderLogoImg() {
    const [isVisible, setIsVisible] = useState(true); // State to control visibility

    useEffect(() => {
        // Toggle visibility every 2 seconds (2000ms)
        const interval = setInterval(() => {
            setIsVisible((prev) => !prev);
        }, 2000);

        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, []);
    return (
        <span className="MainLogo">
            <svg className={isVisible ? 'active' : ''} width="120" height="320" viewBox="0 0 620 820" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M406.956 220.558L193.764 342.645V587.04L406.882 709.053L620 587.04V342.645L406.956 220.558ZM559.565 352.67L406.956 440.15L254.274 352.67L406.956 265.264L559.565 352.67ZM232.547 384.9L387.528 473.791V653.208L232.547 564.465V384.9ZM426.31 653.208V473.791L581.366 384.9V564.391L426.31 653.208Z" fill="url(#paint0_linear_199_490)" className="svg-elem-1" />
                <path d="M406.956 0L193.764 122.012V366.408L406.882 488.421L620 366.408V122.012L406.956 0ZM558.897 132.112L406.215 219.592L253.606 132.112L406.215 44.7057L558.897 132.112ZM232.547 164.342L387.528 253.233V432.65L232.547 343.833V164.342ZM424.679 429.902L426.236 253.159L581.292 164.267L579.734 341.085L424.679 429.902Z" fill="url(#paint1_linear_199_490)" className="svg-elem-2" />
                <path d="M214.972 330.243L0 453.592V697.988L213.118 820L426.236 697.988L428.09 452.255L214.972 330.243ZM365.801 463.691L213.118 551.172L60.5095 463.691L213.192 376.285L365.801 463.691ZM38.7824 495.921L193.838 584.813V764.229L38.7824 675.486V495.921ZM232.546 764.229V584.813L387.602 495.921V675.412L232.546 764.229Z" fill="url(#paint2_linear_199_490)" className="svg-elem-3" />
            </svg>

        </span>
    );
}
