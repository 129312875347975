import React from 'react';
import logo from '../assets/images/Logo.svg';
import { Link,useLocation } from "react-router-dom"
import LogoImg from './svg/logo';


const Header = () => {
  const location = useLocation();

  return (
    <header className='w-full py-[50px]'>
      <div className='container'>
        <div className='flex justify-between items-center'>
          <Link to='/'>
          <LogoImg/>
          </Link>
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <nav class="flex">
            <Link to="/services" className={`text-[#2C2421] text-[15px] after:mx-3 after:content-['|'] relative  ${location.pathname === '/services' ? 'active-link' : ''}`}>
              Services
            </Link>
            <Link to="/our-works" className={`text-[#2C2421] text-[15px] after:mx-3 after:content-['|'] relative  ${location.pathname === '/our-works' ? 'active-link' : ''}`}>
              Our Works
            </Link>
            <Link to="/why-us" className={`text-[#2C2421] text-[15px] after:mx-3 after:content-['|'] relative  ${location.pathname === '/why-us' ? 'active-link' : ''}`}>
            Why us
            </Link>
            <a href="mailto:hr@studioq.co.in" className={`text-[#2C2421] text-[15px] after:mx-3 after:content-['|'] relative  ${location.pathname === '/career' ? 'active-link' : ''}`}>
              Careers
            </a>
            <Link to="/contact-us" className={`text-[#2C2421] text-[15px] relative  ${location.pathname === '/contact-us' ? 'active-link before:!right-0' : ''}`}>
              Contact us
            </Link>
          </nav>

        </div>
      </div>

    </header>
  )
};

export default Header;
