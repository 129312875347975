import React, { useEffect, useState } from "react";
import ContactUsForm from "../../components/contact-form";
import { Helmet } from "react-helmet";





const ContactUsScreen = () => {
    return (
        <div className="w-full mb-[40px]">
            <Helmet>
                <title>Get in Touch – Let’s Build Your Next Digital Solution | StudioQ</title>
            </Helmet>
            <div className="container">
                <div className="grid gap-[40px] grid-cols-12">
                    <div className="col-span-6">
                        <ContactUsForm/>
                    </div>
                    <div className="col-span-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3903.6283214060622!2d79.79530077584722!3d11.930933988296959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a536175613c4c9f%3A0x7b63243b8f548a6!2sStudioQ%20Web%20Solutions!5e0!3m2!1sen!2sin!4v1737903945489!5m2!1sen!2sin" width="600" height="450" className='!w-full min-h-[645px]' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        <div className='w-full flex items-start'>
                            <div className='flex-1 flex items-start mt-[12px]'>
                                <img src='/assets/images/location.svg' />
                                <div className='flex flex-col text-[14px] text-[#2C2421] font-montserrat leading-[22px] ml-[11px]'>
                                    <span>StudioQ Web Solutions</span>
                                    <span>109, Villianur Main Rd, Kavery Nagar,</span>
                                    <span>Reddiarpalayam, Puducherry, India</span>
                                </div>
                            </div>
                            <div className='flex-1 flex items-start mt-[12px]'>
                                <img src='/assets/images/phone.svg' />
                                <div className='flex flex-col text-[14px] text-[#2C2421] font-montserrat leading-[22px] ml-[11px]'>
                                    <a href="tel:+91 98435 77763">+91 98435 77763</a>
                                    <a href="tel:+91 413 438 7186">+91 413 438 7186</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsScreen;