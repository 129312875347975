import React, { useRef } from 'react';
import { Routes, Route } from 'react-router-dom'
import Slider from "react-slick";
import ShuffleTextEffect from '../../components/text-ani';
import Button from '../../components/ui/button';
import CampaignSliderCard from '../../components/ui/campaign-slider';
import ContactUsForm from '../../components/contact-form';
import { Helmet } from 'react-helmet';



const EcommerceService = () => {
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
    };

    const formRef = useRef(null);


    const scrollToForm = () => {
        formRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="w-full mb-[40px]">
            <Helmet>
                <title>eCommerce Website Development – Scalable & High-Converting Stores | StudioQ </title>
            </Helmet>

            <div className="container">
                <div className="grid gap-[50px] gap-y-[35px] grid-cols-12">
                    <div className="col-span-5">
                        <h1 className="font-regular  text-[33px] text-black leading-tight">
                            Build, Scale & Grow Your eCommerce Business with Expert Web Design & Development
                            {/* <ShuffleTextEffect className="text-black text-[39px] font-[Nyght] font-extrabold italic relative bg-transparent !p-0" text="Digital Experiences" /> */}
                        </h1>
                        <div className='flex items-center gap-[25px]'>
                            <Button type="filled" onClick={scrollToForm}>
                                Get a Free Consultation
                            </Button>
                            <img
                                src="/assets/images/years.svg"
                                className="h-[93px] w-[106px]"
                                alt="UI UX"
                            />
                        </div>
                        <p className="text-black text-[39px] font-[Nyght] font-extrabold italic relative bg-transparent !p-0 leading-[45px] mt-[25px]">
                            Custom eCommerce Solutions for Maximum Sales & Performance
                        </p>
                        <p className="mt-[18px] text-[18px] text-[#2C2421] font-light font-montserrat mb-[20px]">
                            Your online store is more than just a website—it’s the foundation of your business success. Our expert eCommerce website design and development services ensure a high-performing, conversion-optimized, and scalable online store tailored to your business needs.
                        </p>
                        <div className='w-full mt-[45px]'>
                            <h3 className="text-[27px] inline-block leading-[25px] mb-4 text-[#2C2421] border-l-6 border-[#FFD600] pl-[5px]">eCommerce Development Services</h3>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                🛒 Custom eCommerce Website Design <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – Beautiful, high-converting online stores built for your brand.
                                </span>
                            </div>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                            📱 Mobile-First & Responsive Design <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – Ensure seamless shopping across all devices.
                                </span>
                            </div>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                            🚀 WooCommerce Development <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – Flexible, feature-rich WordPress eCommerce solutions.
                                </span>
                            </div>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                            💎 Shopify Store Setup & Optimization <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – From custom themes to Shopify Plus scaling.
                                </span>
                            </div>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                            🛍️ Magento Development <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – Powerful, scalable, and feature-rich Magento solutions.
                                </span>
                            </div>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                🌐 BigCommerce Development <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – High-performance, scalable eCommerce stores.
                                </span>
                            </div>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                            🛠️ Medusa JS & Headless eCommerce <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – Future-ready, API-driven eCommerce development.
                                </span>
                            </div>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                            🔌 Custom Integrations & API Development <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – Payment gateways, logistics, ERP, and CRM integration.
                                </span>
                            </div>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                            📈 SEO & Performance Optimization <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – Ensure fast loading, high rankings & better conversions.
                                </span>
                            </div>

                            <div className='w-full mt-[21px] text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                            🔄 Migrations & Upgrades <span className='text-[#2C2421] text-[14px] font-instrument leading-[23px] font-normal'>
                                    – Move your store to a better platform without downtime.
                                </span>
                            </div>

                        </div>
                        <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px] mt-[75px]">Our Works</h3>
                        <div className='w-full'>
                            <Slider {...settings} className="clientSlider">
                                {/* Slider 1 */}
                                <div className="w-full">
                                    <CampaignSliderCard
                                        description="Part-Timer connects employers with part-time job seekers, making it easy to post jobs and find roles that fit skills and schedules."

                                        tags={["Product Design", "UI/UX", "App Design", "Web Design"]}
                                        sliderImage="/assets/images/slider-pt.svg"
                                    />
                                </div>
                                {/* Slider 2 */}
                                <div className="w-full">
                                    <CampaignSliderCard
                                        description="A custom WordPress site for Kalakshetra Foundation, showcasing their heritage and legacy with an engaging user experience."

                                        tags={['Product Design', 'UI/UX', 'App Design', 'Web Design']}
                                        sliderImage="/assets/images/kalakshetra-bg.svg"
                                    />
                                </div>
                                {/* Slider 3 */}
                                <div className="w-full">
                                    <CampaignSliderCard
                                        description="An innovative platform designed to empower aspiring and novice cooks by enabling them to learn directly from established chefs."

                                        tags={['Product Design', 'UI/UX', 'App Design']}
                                        sliderImage="/assets/images/slider-cook-along.svg"
                                    />
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="col-span-7">
                        <div className='w-full relative'>
                            <img
                                src="/assets/images/bg-logo.svg"
                                className="absolute top-0 left-0 right-0"
                                alt="UI UX"
                            />
                            <div className='w-full flex items-center gap-[40px]'>
                                <div className='relative items-end campain-card bg-[#DFDFDF]' onClick={scrollToForm}>

                                    <img
                                        src="/assets/images/ecom-1.svg"
                                        className=""
                                        alt="UI UX"
                                    />
                                    <div className='flex items-center justify-between gap-4 absolute bottom-[20px] left-[20px] right-[20px]'>
                                        <div className='text-[#2C2421] text-[16px] font-semibold font-instrument leading-[23px]'>
                                            Go Mobile & Start Selling Anywhere!
                                        </div>
                                        <button className='contents'>
                                            <img
                                                src="/assets/images/next.svg"
                                                className='w-[35px] h-[35px]'
                                                alt="UI UX"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className='relative items-end campain-card bg-[#DFDFDF]' onClick={scrollToForm}>

                                    <img
                                        src="/assets/images/ecom-2.svg"
                                        className=""
                                        alt="Product Icon"
                                    />
                                    <div className='flex items-center justify-between gap-4 absolute bottom-[20px] left-[20px] right-[20px]'>
                                        <div className='text-[#2C2421] text-[16px] font-semibold font-instrument leading-[23px]'>
                                            Launch Your Online Store Today & Start Selling with Ease!
                                        </div>
                                        <button className='contents'>
                                            <img
                                                src="/assets/images/next.svg"
                                                className='w-[35px] h-[35px]'
                                                alt="UI UX"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full mt-[130px] bg-[#FFD600] relative z-1'>
                                <div className='w-full mt-[45px] p-[40px] pb-[15px]'>
                                    <h3 className="text-[27px] inline-block leading-[25px] mb-4 text-[#2C2421] border-l-6 border-[#fff] pl-[5px]">Why Choose Us?</h3>
                                    <div className='w-full mt-[20px]  inline-block'>
                                        <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                            ✔ Custom eCommerce Design
                                        </div>
                                        <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                            We create stunning, user-friendly designs that enhance shopping experiences.
                                        </div>
                                    </div>
                                    <div className='w-full mt-[20px]  inline-block'>
                                        <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                            ✔ Conversion-Optimized UI/UX
                                        </div>
                                        <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                            Boost sales with seamless navigation, engaging visuals, and mobile-friendly designs.
                                        </div>
                                    </div>
                                    <div className='w-full mt-[20px]  inline-block'>
                                        <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                            ✔ Speed & Performance Focused
                                        </div>
                                        <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                            Fast-loading, SEO-optimized stores for higher rankings and better user experience.
                                        </div>
                                    </div>
                                    <div className='w-full mt-[20px]  inline-block'>
                                        <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                            ✔ Multi-Platform Expertise
                                        </div>
                                        <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                            We build & optimize stores on WooCommerce, Magento, Shopify, BigCommerce, Medusa JS, and more.
                                        </div>
                                    </div>
                                    <div className='w-full mt-[20px]  inline-block'>
                                        <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                            ✔ Scalability & Security
                                        </div>
                                        <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                            Future-proof solutions with high security and seamless scalability.
                                        </div>
                                    </div>
                                    <div className='w-full mt-[20px]  inline-block'>
                                        <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                            ✔ Third-Party Integrations
                                        </div>
                                        <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                            Payment gateways, CRM, inventory management & more.
                                        </div>
                                    </div>
                                    <div className='w-full mt-[20px]  inline-block'>
                                        <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                            ✔ Custom Development & Plugins
                                        </div>
                                        <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                            Extend functionality to meet your unique business needs.
                                        </div>
                                    </div>
                                </div>
                                <div ref={formRef}>
                                <ContactUsForm formDescClass="hidden" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EcommerceService;