import React from 'react';
import { Link } from "react-router-dom"
import HomeBannerSection from './components/banner';
import AboutUsSection from './components/about-us';
import PortfolioSlider from './components/portfolio';
import HomeServiceSection from './components/services';
import { Helmet } from 'react-helmet';



const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>StudioQ – 17+ Years of Excellence in UI/UX, Web & Mobile App Development</title>
      </Helmet>
      <HomeBannerSection />
      <AboutUsSection />
      <PortfolioSlider />
      <HomeServiceSection />
    </>
  )
};

export default HomePage;