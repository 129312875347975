import React, { useState, useEffect } from "react";

const ShuffleTextEffect = ({ text, duration = 100,className }) => {
    const charset =
      "1234567890abcdefghijklmn";
    const [displayText, setDisplayText] = useState("");
    const [index, setIndex] = useState(0);
  
    useEffect(() => {
      const shuffleInterval = 30; 
      const steps = Math.ceil(duration / shuffleInterval); 
      const textArray = Array.from(text); 
      const resultText = Array(text.length).fill(""); 
  
      let stepCount = 0;
  
      const intervalId = setInterval(() => {
        const newText = textArray.map((char, i) => {
          if (i <= index) {
            return resultText[i] || char;
          }
          return charset[Math.floor(Math.random() * charset.length)];
        });
  
        setDisplayText(newText.join(""));
        stepCount++;
  
        if (stepCount >= steps) {
          resultText[index] = text[index];
          setIndex((prevIndex) => prevIndex + 1);
          stepCount = 0;
        }
  
        if (index >= text.length) {
          clearInterval(intervalId); 
          setDisplayText(text); 
        }
      }, shuffleInterval);
  
      return () => clearInterval(intervalId);
    }, [index, text, charset, duration]);
  
    return (
      <span className={className} style={{padding: '0 5px'}}>
        {displayText}
      </span>
    );
  };

  export default ShuffleTextEffect;