import React from "react";
import PropTypes from "prop-types";
import Tag from "./tags";

const CampaignSliderCard = ({ description, tags, sliderImage,sliderImageClass,cardClass }) => {
  return (
    <div className={` ${cardClass} mt-[40px] `}>
        <div className="w-full border border-[#AB9E99]">
          {/* Top Section */}
          <div className="w-full bg-white">
          <img
            src={sliderImage}
            className={` ${sliderImageClass} top-[-30px]  w-full h-[272px] relative`}
            alt="Product Icon"
          />
          </div>
          {/* Bottom Section */}
          <div className="w-full bg-[#F2F0EA] p-[20px] mt-[-30px] flex gap-[10px] min-h-[160px]">
            {/* <div className="w-full">
              <img src={logoSrc} className="mb-[22px]" alt="Product Icon" />
            </div> */}
            <p className="text-[14px] leading-[24px] text-[#000000] font-light font-instrument flex-1">
              {description}
            </p>
            <div className="w-full  flex-1">
              <Tag texts={tags} />
            </div>
          </div>
          
        </div>
      </div>
  );
};

CampaignSliderCard.propTypes = {
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  sliderImage: PropTypes.string.isRequired,
  sliderImageClass: PropTypes.string,
  cardClass: PropTypes.string,
};

export default CampaignSliderCard;
