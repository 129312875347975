import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './home/home';
import ServicePage from './services/services';
import OurWorks from './our-works/our-works';
import WhyUsScreen from './why-us/why-us'
import ScrollToTop from '../components/ScrollToTop';
import ContactUsScreen from './contact-us/contact-us';
import UIUXDesignService from './campaign/ui-ux-design-services';
import EcommerceService from './campaign/ecommerce-website-development';


const PageRoutes = () => {
  return (
    <>
      <ScrollToTop/>
      <Routes >
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/our-works" element={<OurWorks />} />
        <Route path="/why-us" element={<WhyUsScreen />} />
        <Route path="/contact-us" element={<ContactUsScreen />} />
        <Route path="/ui-ux-design-services" element={<UIUXDesignService />} />
        <Route path="/ecommerce-website-development" element={<EcommerceService />} /> 
      </Routes>
    </>
  );
};

export default PageRoutes;