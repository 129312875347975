import React from "react";
import Slider from "react-slick";


const HomeServiceSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // fade: true,
    nextArrow: <img src='/assets/images/next.svg' className="w-[28px] h-[28px]" alt="next" />,
    prevArrow: <img src='/assets/images/prev.svg' className="w-[28px] h-[28px]" alt="prev" />
  };
  return (
    <div className="container mb-[30px] mt-[50px]">
      
        <div className="grid gap-[50px] gap-y-[35px] grid-cols-12 items-center relative">
          {/* Left Section */}
          <div className="col-span-12">
          <h3 className="text-[22px] leading-[28px] font-regular text-[#2C2421] border-l-6 border-[#FFD600] pl-[9px]">We’re Ready to Collaborate in Every Way</h3>
          </div>
          <div className="col-span-8">
            <div className="grid gap-[50px] grid-cols-12 items-start relative">
            <div className="col-span-4">
                <div className='flex flex-col items-start'>
                    <img src='/assets/images/mvp.svg' className="mb-[20px]" alt="ProductIcon" />
                    <h3 className="text-[14px] text-[#2C2421] font-semibold font-instrument leading-[24px]">MVP Development</h3>
                    <h3 className="text-[14px] text-[#2C2421] font-regular font-instrument leading-[24px]">We turn innovative ideas into actionable products. Launch quickly, focus on core features, and refine as you grow to maximize impact.</h3>
                </div>
            </div>
            <div className="col-span-4">
                <div className='flex flex-col items-start'>
                    <img src='/assets/images/redesign.svg' className="mb-[20px]" alt="ProductIcon" />
                    <h3 className="text-[14px] text-[#2C2421] font-semibold font-instrument leading-[24px]">Redesign Services</h3>
                    <h3 className="text-[14px] text-[#2C2421] font-regular font-instrument leading-[24px]">From large-scale platforms to niche solutions, we refresh and elevate designs to meet new objectives, enhance usability, and drive growth.</h3>
                </div>
            </div>
            <div className="col-span-4">
                <div className='flex flex-col items-start'>
                    <img src='/assets/images/team.svg' className="mb-[20px]" alt="ProductIcon" />
                    <h3 className="text-[14px] text-[#2C2421] font-semibold font-instrument leading-[24px]">Team Augmentation</h3>
                    <h3 className="text-[14px] text-[#2C2421] font-regular font-instrument leading-[24px]">Boost your team with our expertise and resources. We integrate seamlessly to accelerate development and optimize your product’s potential.</h3>
                </div>
            </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="col-span-4 relative flex justify-center h-full">
            <div className="border border-[#000] py-[27px] px-[27px] w-full">
            <h3 className="text-[22px] leading-[44px] font-regular text-[#2C2421]">What Our Clients Say</h3>
            <Slider {...settings} className="clientSlider">
              <div className="relative pb-[15px] text-[13px] text-[#2C2421] font-regular font-instrument leading-[24px]">
              {/* <img src='/assets/images/cook-along.svg' className="mb-[10px] mt-[16px]" alt="ProductIcon" /> */}
              <h3 className="">StudioQ has been a long associate of Feel-Inde in improving web developments, analytics and seo. Always energetic & focussed team is the one which I love in StudioQ. We started together and hope the relay continues to next generation.</h3>
              <div className="font-bold mt-[15px]">- Narayanan Ponnambalam</div>
              <div className="">Founder at SARL FEEL-INDE</div>
              <img src='/assets/images/quot.svg' className="absolute top-0 right-0 z-[-1]" alt="ProductIcon" />
              </div>

              <div className="relative pb-[15px] text-[13px] text-[#2C2421] font-regular font-instrument leading-[24px]">
              <h3 className="">For over 15 years, I’ve partnered with Studio Q on various web projects. Their expertise spans from early HTML and PHP to modern full-stack apps. They consistently deliver high-quality work on time, demonstrating dedication throughout our collaboration.</h3>
              <div className="font-bold mt-[15px]">- Krishna Prathab R V</div>
              <div className="">Founder & CEO at Hopsticks</div>
              <img src='/assets/images/quot.svg' className="absolute top-0 right-0 z-[-1]" alt="ProductIcon" />
              </div>

              <div className="relative pb-[15px] text-[13px] text-[#2C2421] font-regular font-instrument leading-[24px]">
              <h3 className="">I’ve had the pleasure of working with StudioQ on multiple projects, and their services have truly transformed our operations. Their dedication, insight, and technical skills stand out in every interaction.</h3>
              <div className="font-bold mt-[15px]">- Anand Balakrishnan</div>
              <div className="">Founder at Cerebral-Works</div>
              <img src='/assets/images/quot.svg' className="absolute top-0 right-0 z-[-1]" alt="ProductIcon" />
              </div>

              <div className="relative pb-[15px] text-[13px] text-[#2C2421] font-regular font-instrument leading-[24px]">
              <h3 className="">StudioQ has been our trusted partner for 14+ years, consistently delivering innovative solutions that drive our growth. Their reliability and dedication make them invaluable, and we look forward to many more years of collaboration!</h3>
              <div className="font-bold mt-[15px]">- Fayaz Ahamad</div>
              <div className="">Founder, Yalmaa Digital Agency</div>
              <img src='/assets/images/quot.svg' className="absolute top-0 right-0 z-[-1]" alt="ProductIcon" />
              </div>
             
            </Slider>
            </div>            
          </div>
        </div>
      </div>
  );
};

export default HomeServiceSection;
