import React from "react";
import Slider from "react-slick";
import { FadeIn } from "../../../components/animations";
import Tag from '../../../components/ui/tags';
import PortfolioCard from "../../../components/ui/portfolio-card";



const PortfolioSlider = () => {

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    nextArrow: <img src='/assets/images/next.svg' className="w-[28px] h-[28px]" alt="next" />,
    prevArrow: <img src='/assets/images/prev.svg' className="w-[28px] h-[28px]" alt="prev" />
  };

  return (

    <div className="container mt-[175px] mb-[30px] portfolio-slider">
      <Slider {...settings} className=" mt-[-45px]">
        {/* Slider 1 */}
        <PortfolioCard
          backgroundImage="/assets/images/portfolio-bg.svg"
          logoSrc="/assets/images/part-timer-logo.svg"
          description="Part-Timer connects employers with part-time job seekers, making it easy to post jobs and find roles that fit skills and schedules."
          roles={[
            "UI/UX Design: Intuitive interface for seamless navigation.",
            "Development: Scalable platform for job posting and search.",
            "Integration: Real-time updates and secure workflows."
          ]}
          results={[
            "Enhanced job accessibility for part-timers.",
            "Faster, streamlined hiring for employers."
          ]}
          tags={["Product Design", "UI/UX", "App Design", "Web Design"]}
          sliderImage="/assets/images/slider-pt.svg"
        />
        {/* Slider 2 */}
        <PortfolioCard
          backgroundImage="/assets/images/portfolio-bg.svg"
          logoSrc="/assets/images/kalakshetra.svg"
          description="A custom WordPress site for Kalakshetra Foundation, showcasing their heritage and legacy with an engaging user experience."
          roles={[
            "UI/UX Design: Designed a culturally inspired, aesthetic interface.",
            "WordPress Development: Developed a custom site in WordPress",
            "Integration: Enabled dynamic content for events and programs."
          ]}
          results={[
            "Enhanced online visibility and user engagement.",
            "Simplified content management for the foundation team."
          ]}
          tags={['Product Design', 'UI/UX', 'App Design', 'Web Design']}
          sliderImage="/assets/images/kalakshetra-bg.svg"
          sliderImageClass="top-[-135px]"
        />
        {/* Slider 3 */}
        <PortfolioCard
          backgroundImage="/assets/images/portfolio-bg.svg"
          logoSrc="/assets/images/cook-along.svg"
          description="An innovative platform designed to empower aspiring and novice cooks by enabling them to learn directly from established chefs."
          roles={[
            "UI/UX Design: Crafted an intuitive interface for seamless booking.",
            "Development: Built a robust platform enabling smooth live-streaming and real-time interactions.",
          ]}
          results={[
            "Enhanced engagement between chefs and aspiring cooks.",
            "A growing community of passionate learners and culinary experts."
          ]}
          tags={['Product Design', 'UI/UX', 'App Design']}
          sliderImage="/assets/images/slider-cook-along.svg"
          sliderImageClass="top-[-135px]"
        />


      </Slider>
    </div>
  );
};

export default PortfolioSlider;
