import React, { useEffect, useState } from "react";
import { Routes, Route } from 'react-router-dom'
import emailjs from "@emailjs/browser";
import LoaderLogoImg from "./loader/loader";
import PropTypes from "prop-types";
import Button from "./ui/button";





const ContactUsForm = ({formDescClass}) => {
    const [formData, setFormData] = useState({
        contactName: "",
        contactNumber: "",
        businessName: "",
        email: "",
        message: "",
    });

    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState(null);


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js?render=6Ld3mMYqAAAAAIvx6UpEiRYa5WM2LfaqRczPVmK1";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.contactName.trim()) {
            newErrors.contactName = "Contact Name is required.";
        }

        if (!formData.contactNumber.trim()) {
            newErrors.contactNumber = "Contact Number is required.";
        } else if (!/^\d{10,15}$/.test(formData.contactNumber)) {
            newErrors.contactNumber = "Contact Number must be min 10 digits.";
        }

        if (!formData.businessName.trim()) {
            newErrors.businessName = "Business Name is required.";
        }

        if (!formData.email.trim()) {
            newErrors.email = "Email is required.";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = "Invalid email address.";
        }

        if (!formData.message.trim()) {
            newErrors.message = "Message is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        const limits = {
            contactName: 20,
            contactNumber: 15,
            businessName: 30,
            message: 600,
        };

        if (!limits[id] || value.length <= limits[id]) {
            setFormData({ ...formData, [id]: value });
        }

        setErrors({ ...errors, [id]: "" });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const recaptchaToken = await window.grecaptcha.execute('6Ld3mMYqAAAAAIvx6UpEiRYa5WM2LfaqRczPVmK1', { action: 'submit' });
            setToken(recaptchaToken);

            if (!validateForm()) return;

            if (!recaptchaToken) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    recaptcha: "reCAPTCHA verification failed. Please try again.",
                }));
                return;
            }

            setIsLoading(true);


            emailjs
                .send(
                    "service_bhz4tbn",
                    "template_szdq8kb",
                    {
                        contact_name: formData.contactName,
                        contact_number: formData.contactNumber,
                        business_name: formData.businessName,
                        contact_email: formData.email,
                        message: formData.message,
                        'g-recaptcha-response': recaptchaToken,
                    },
                    "iihU67rGm9XwLoGWw"
                )
                .then(
                    (response) => {
                        console.log("SUCCESS!", response.status, response.text);
                        setIsSubmitted(true);
                        setIsLoading(false);
                        setFormData({
                            contactName: "",
                            contactNumber: "",
                            businessName: "",
                            email: "",
                            message: "",
                        });
                        setToken(null);


                        window.grecaptcha.execute('6Ld3mMYqAAAAAIvx6UpEiRYa5WM2LfaqRczPVmK1', { action: 'submit' }).then((newToken) => {
                            setToken(newToken);
                        });

                    },
                    (err) => {
                        setIsLoading(false);
                        console.error("FAILED...", err);
                    }
                );
        } catch (error) {
            setIsLoading(false);
            setErrors((prevErrors) => ({
                ...prevErrors,
                recaptcha: "reCAPTCHA verification failed. Please try again.",
            }));
            console.error("reCAPTCHA error:", error);
        }
    };
    return (
        <div className="bg-[#FFD600] flex items-center justify-center px-[40px] py-[32px] relative w-full">
            <div className="max-w-4xl w-full bg-[#FFD600] rounded-lg">
                <h1 className="font-regular  text-[33px] text-black leading-tight">
                    Let’s Connect and Create Something <span className='text-black text-[39px] font-[Nyght] font-extrabold italic relative bg-[#FFD600]'>Amazing Together</span>
                </h1>
                <p className={` ${formDescClass} text-[14px] text-[#2C2421] font-montserrat py-[16px]`}>
                    Have a question, a project idea, or just want to say hello? We’re here to help! Reach out to us now.
                </p>
                <form className="space-y-[30px] mt-[15px]" onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[35px]">
                        <div className="relative">
                            <label
                                className="text-[14px] text-[#2C2421] font-montserrat mb-[5px]"
                                htmlFor="contactName"
                            >
                                Contact Name
                            </label>
                            <input
                                type="text"
                                id="contactName"
                                value={formData.contactName}
                                onChange={handleChange}
                                className="w-full p-3 !outline-none text-[14px] font-montserrat h-[55px]"
                            />
                            {errors.contactName && (
                                <p className="text-red-500 text-[13px] absolute bottom-[-20px]">{errors.contactName}</p>
                            )}
                        </div>
                        <div className="relative">
                            <label
                                className="text-[14px] text-[#2C2421] font-montserrat mb-[5px]"
                                htmlFor="contactNumber"
                            >
                                Contact Number
                            </label>
                            <input
                                type="number"
                                id="contactNumber"
                                value={formData.contactNumber}
                                onChange={handleChange}
                                className="w-full p-3 !outline-none text-[14px] font-montserrat h-[55px]"
                            />
                            {errors.contactNumber && (
                                <p className="text-red-500 text-[13px] absolute bottom-[-20px]">{errors.contactNumber}</p>
                            )}
                        </div>
                        <div className="relative">
                            <label
                                className="text-[14px] text-[#2C2421] font-montserrat mb-[5px]"
                                htmlFor="businessName"
                            >
                                Business Name
                            </label>
                            <input
                                type="text"
                                id="businessName"
                                value={formData.businessName}
                                onChange={handleChange}
                                className="w-full p-3 !outline-none text-[14px] font-montserrat h-[55px]"
                            />
                            {errors.businessName && (
                                <p className="text-red-500 text-[13px] absolute bottom-[-20px]">{errors.businessName}</p>
                            )}
                        </div>
                        <div className="relative">
                            <label
                                className="text-[14px] text-[#2C2421] font-montserrat mb-[5px]"
                                htmlFor="email"
                            >
                                Official Email ID
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full p-3 !outline-none text-[14px] font-montserrat h-[55px]"
                            />
                            {errors.email && <p className="text-red-500 text-[13px] absolute bottom-[-20px]">{errors.email}</p>}
                        </div>
                    </div>
                    <div className="relative">
                        <label
                            className="text-[14px] text-[#2C2421] font-montserrat mb-[5px]"
                            htmlFor="message"
                        >
                            Share your idea or inquiry
                        </label>
                        <textarea
                            id="message"
                            rows="5"
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full p-3 !outline-none text-[14px] font-montserrat"
                        ></textarea>
                        {errors.message && <p className="text-red-500 text-[13px] absolute bottom-[-20px]">{errors.message}</p>}
                    </div>
                    <div className="relative">
                        <Button
                            type="submit"
                            outlineClasses="hover:!border hover:!border-[#000] hover:!text-[#000]"
                            className="btn-borer-hover px-[50px] w-full border border-transparent font-medium"
                        >
                            <span>Submit Now</span>
                        </Button>
                        {isSubmitted && (
                            <p className="text-black font-semibold text-sm mt-2">
                                Your message has been sent successfully!
                            </p>
                        )}
                    </div>
                </form>
            </div>
            {isLoading && (<div className="absolute inset-0 bg-black bg-opacity-80 flex items-center justify-center">
                <LoaderLogoImg />
            </div>
            )}
        </div>
    );
};

ContactUsForm.propTypes = {
    formDescClass: PropTypes.string,
};

export default ContactUsForm;