import React from "react";
import PortfolioCard from "../../components/ui/portfolio-card";
import ShuffleTextEffect from "../../components/text-ani";
import { Helmet } from "react-helmet";



const OurWorks = () => {
    return (
        <div className="container mb-[30px] portfolio-slider">
            <Helmet>
                <title>Our Works – Stunning UI/UX, Web & App Projects | StudioQ</title>
            </Helmet>
            <h1 className="font-regular  text-[33px] text-black leading-tight">
                Our Works: Turning Ideas into  <br /> Impactful Digital
                <ShuffleTextEffect className="text-black text-[46px] font-[Nyght] font-extrabold italic relative bg-[#FFD600]" text="Experiences" />
            </h1>
            {/* Slider 1 */}
            <div className="w-full mb-[60px]">
                <PortfolioCard
                    backgroundImage="/assets/images/portfolio-bg.svg"
                    logoSrc="/assets/images/part-timer-logo.svg"
                    description="Part-Timer connects employers with part-time job seekers, making it easy to post jobs and find roles that fit skills and schedules."
                    roles={[
                        "UI/UX Design: Intuitive interface for seamless navigation.",
                        "Development: Scalable platform for job posting and search.",
                        "Integration: Real-time updates and secure workflows."
                    ]}
                    results={[
                        "Enhanced job accessibility for part-timers.",
                        "Faster, streamlined hiring for employers."
                    ]}
                    tags={["Product Design", "UI/UX", "App Design", "Web Design"]}
                    sliderImage="/assets/images/slider-pt.svg"
                />
            </div>
            {/* Slider 2 */}
            <div className="w-full mb-[60px]">
                <PortfolioCard
                    backgroundImage="/assets/images/portfolio-bg.svg"
                    logoSrc="/assets/images/kalakshetra.svg"
                    description="A custom WordPress site for Kalakshetra Foundation, showcasing their heritage and legacy with an engaging user experience."
                    roles={[
                        "UI/UX Design: Designed a culturally inspired, aesthetic interface.",
                        "WordPress Development: Developed a custom site in WordPress",
                        "Integration: Enabled dynamic content for events and programs."
                    ]}
                    results={[
                        "Enhanced online visibility and user engagement.",
                        "Simplified content management for the foundation team."
                    ]}
                    tags={['Product Design', 'UI/UX', 'App Design', 'Web Design']}
                    sliderImage="/assets/images/kalakshetra-bg.svg"
                    sliderImageClass="top-[-135px]"
                />
            </div>
            {/* Slider 3 */}
            <div className="w-full mb-[60px]">
                <div
                    className="mx-auto min-h-[489px] bg-cover bg-no-repeat bg-left-center"
                    style={{ backgroundImage: `url('/assets/images/portfolio-bg.svg')` }}
                >
                    <div className="grid gap-[50px] grid-cols-12 items-center relative">
                        {/* Left Section */}
                        <div className="col-span-8">
                            <div className="w-full h-full flex items-center justify-center">
                                <img src='/assets/images/natural-promise.svg' className="mb-[0]" alt="Product Icon" />
                            </div>
                        </div>
                        {/* Right Section */}
                        <div className="col-span-4">
                            <div className="w-full h-full flex items-center justify-center">
                                <img src='/assets/images/natural-promise1.svg' className="mb-[0]" alt="Product Icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Slider 4 */}
            <div className="w-full mb-[60px]">
                <PortfolioCard
                    backgroundImage="/assets/images/portfolio-bg.svg"
                    logoSrc="/assets/images/cook-along.svg"
                    description="An innovative platform designed to empower aspiring and novice cooks by enabling them to learn directly from established chefs."
                    roles={[
                        "UI/UX Design: Crafted an intuitive interface for seamless booking.",
                        "Development: Built a robust platform enabling smooth live-streaming and real-time interactions.",
                    ]}
                    results={[
                        "Enhanced engagement between chefs and aspiring cooks.",
                        "A growing community of passionate learners and culinary experts."
                    ]}
                    tags={['Product Design', 'UI/UX', 'App Design']}
                    sliderImage="/assets/images/slider-cook-along.svg"
                    sliderImageClass="top-[-135px]"
                />
            </div>
            {/* Slider 5 */}
            <div className="w-full mb-[60px]">
                <div
                    className="mx-auto min-h-[489px] bg-cover bg-no-repeat bg-left-center"
                    style={{ backgroundImage: `url('/assets/images/portfolio-bg.svg')` }}
                >
                    <div className="grid gap-[50px] grid-cols-12 items-center relative">
                        {/* Left Section */}
                        <div className="col-span-8">
                            <div className="w-full h-full flex items-center justify-center">
                                <img src='/assets/images/peanut.svg' className="mb-[0]" alt="Product Icon" />
                            </div>
                        </div>
                        {/* Right Section */}
                        <div className="col-span-4">
                            <div className="w-full h-full flex items-center justify-center">
                                <img src='/assets/images/peanut1.svg' className="mb-[0]" alt="Product Icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Slider 6 */}
            <div className="w-full mb-[60px]">
                <PortfolioCard
                    cardClass="!pt-[110px]"
                    backgroundImage="/assets/images/portfolio-bg.svg"
                    logoSrc="/assets/images/varahi-logo.svg"
                    description="We crafted a tailored WordPress website for Varahi Exports to showcase their products and global reach."
                    roles={[
                        "UI/UX Design: Clean, user-focused design.",
                        "WordPress Development: Scalable, custom-built site.",
                        "Integration: Dynamic content management for product updates."
                    ]}
                    results={[
                        "Boosted online presence and global inquiries.",
                        "Simplified content management for the foundation team."
                    ]}
                    tags={['Web  Design', 'UI/UX', 'Branding']}
                    sliderImage="/assets/images/varahi-slider.svg"
                    sliderImageClass="top-[-135px] min-h-[570px] right-[-50px]"
                />
            </div>
            {/* Slider 7 */}
            <div className="w-full mb-[60px]">
                <div
                    className="mx-auto min-h-[489px] bg-cover bg-no-repeat bg-left-center"
                    style={{ backgroundImage: `url('/assets/images/portfolio-bg.svg')` }}
                >
                    <div className="grid gap-[50px] grid-cols-12 items-center relative">
                        {/* Left Section */}
                        <div className="col-span-6">
                            <div className="w-full h-full flex items-center justify-center">
                                <img src='/assets/images/virtus.svg' className="mb-[0]" alt="Product Icon" />
                            </div>
                        </div>
                        {/* Right Section */}
                        <div className="col-span-6">
                            <div className="w-full h-full flex items-center justify-center">
                                <img src='/assets/images/virtus1.svg' className="mb-[0]" alt="Product Icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Slider 4 */}
            <div className="w-full mb-[60px]">
                <PortfolioCard
                    backgroundImage="/assets/images/portfolio-bg.svg"
                    logoSrc="/assets/images/buybids-logo.svg"
                    description="A mobile app that simplifies used car bidding. Users can explore listings, place bids, and secure great deals through a seamless, secure platform."
                    roles={[
                        "UI/UX Design: Intuitive interface for easy bidding.",
                        "Mobile App Development: Cross-platform app for iOS and Android.",
                        "Backend Integration: Secure data handling with real-time updates."
                    ]}
                    results={[
                        "Streamlined car bidding process.",
                        "Increased user engagement and transactions."
                    ]}
                    tags={['Product Design', 'Branding', 'App Design', 'UI/UX']}
                    sliderImage="/assets/images/buybids-bg.svg"
                    sliderImageClass="top-[-135px]"
                />
            </div>
            {/* Slider 6 */}
            <div className="w-full mb-[60px]">
                <PortfolioCard
                    backgroundImage="/assets/images/portfolio-bg.svg"
                    logoSrc="/assets/images/namma-rest-logo.svg"
                    description="We created a custom website, integrating Square API for real-time product updates and seamless online ordering, making takeaway orders quick and easy."
                    roles={[
                        "UI/UX Design: Modern, user-friendly interface.",
                        "Custom Development: Scalable website with ordering features.",
                        "Square API Integration: Real-time product updates and secure payments."
                    ]}
                    results={[
                        "Enhanced online ordering experience.",
                        "Simplified order management."
                    ]}
                    tags={['Web  Design', 'eCommerce', 'UI/UX']}
                    sliderImage="/assets/images/namma-rest-bg.svg"
                    sliderImageClass="top-[-88px] min-h-[570px] right-[-50px]"
                />
            </div>
        </div>
    );
};

export default OurWorks;
