import React from 'react';
import { Link,useNavigate } from "react-router-dom"
import ShuffleTextEffect from "../../../components/text-ani";
import Marquee from "react-fast-marquee";
import Tag from '../../../components/ui/tags';
import Button from '../../../components/ui/button';


const AboutUsSection = () => {
    const navigate = useNavigate();
    return (
        <div className='w-full mt-[40px] pt-[90px] relative'>

            <div className='absolute left-0 top-0 w-1/2 h-[70%] bg-contain bg-no-repeat [background-position:top_0_right_0]' style={{ backgroundImage: "url('/assets/images/bg-logo.svg')" }}></div>
            <div className='container'>
                <div class="grid gap-[50px] grid-cols-12">
                    <div className='col-span-6 flex flex-col justify-between mb-[-200px] relative'>

                        <div className='w-full'>
                            {/* <img src='/assets/images/bg-logo.svg' className="mr-[10px]" alt="Reactjs" /> */}
                        </div>
                        <div className='w-full z-[9]'>
                            <img src='/assets/images/about_us.svg' className="mr-[10px]" alt="Reactjs" />
                            <h1 className="font-regular  text-[33px] text-[#2C2421] leading-tight block mt-[45px]">
                                Partner with us for innovative designs and robust development solutions that elevate your digital presence.
                            </h1>
                            <div className='flex items-center gap-2 justify-between my-[30px]'>
                                <Button type="filled" onClick={() => navigate("/contact-us")}>
                                    Let’s Build Your Project Together
                                </Button>
                                <Button type="outline" className="btn-borer-hover px-[50px]" onClick={() => navigate("/our-works")}>
                                    <span>Explore Our Portfolio</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-6'>
                        {/* Header Section */}
                        <section className="text-left px-4">
                            <h1 className="font-regular  text-[33px] text-black leading-tight">
                                Mastering Journey-Centric <br />Solutions for
                                <ShuffleTextEffect className='text-black text-[33px] font-[Nyght] font-extrabold italic relative bg-[#FFD600] w-[130px] inline-block text-nowrap' text="17 Years" />{" "}
                                and Counting
                            </h1>
                            <p className="mt-2 text-[18px] text-[#2C2421] font-light font-instrument">
                                For 17 years, we’ve been at the forefront of delivering intuitive, engaging, and visually
                                stunning digital experiences. From designing user-friendly interfaces to creating seamless
                                user journeys, our expertise ensures every interaction feels natural and impactful.
                            </p>
                        </section>

                        {/* Services Section */}
                        <section className="mt-[40px] px-4">
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                                <div className='flex items-center'>
                                    <img src='/assets/images/product.svg' className="mr-[10px]" alt="ProductIcon" />
                                    <h3 className="text-[17px] text-[#2C2421] font-medium font-instrument leading-[20px]">Product Vision & Strategy</h3>
                                </div>
                                <div className='flex items-center'>
                                    <img src='/assets/images/uiux.svg' className="mr-[10px]" alt="ProductIcon" />
                                    <h3 className="text-[17px] text-[#2C2421] font-medium font-instrument leading-[20px]">UI/UX Design Excellence</h3>
                                </div>
                                <div className='flex items-center'>
                                    <img src='/assets/images/design.svg' className="mr-[10px]" alt="ProductIcon" />
                                    <h3 className="text-[17px] text-[#2C2421] font-medium font-instrument leading-[20px]">DesignOps <br /> Solutions</h3>
                                </div>
                            </div>
                        </section>

                        {/* Our Services Section */}
                        <section className="py-12 pb-8 px-4">
                            <h2 className="font-regular text-[#2C2421] text-[27px] leading-tight mb-6">
                                We Create <span className="underline">Apps, Websites & Brands</span> That Work.
                            </h2>
                            <div className="mb-8">
                                <h3 className="text-[15px] font-bold font-instrument mb-4 text-[#2C2421] border-l-6 border-[#FFD600] pl-[5px]">Our Web & Mobile App Services</h3>
                                <Tag texts={['User Flow', 'Wireframes', 'UI/UX Design', 'Design to Code']} />
                            </div>

                            <p className="mt-2 text-[14px] leading-[27px] text-[#000000] font-light font-instrument">
                                For 17 years, we’ve been at the forefront of delivering intuitive, engaging, and visually
                                stunning digital experiences. From designing user-friendly interfaces to creating seamless
                                user journeys, our expertise ensures every interaction feels natural and impactful.
                            </p>

                            <div className="flex items-center justify-between gap-8 py-[30px] overflow-hidden relative">
                                <Marquee className='imageMarquee' autoFill={true}>
                                    <img src='/assets/images/reactjs.svg' className="mx-[20px]" alt="Reactjs" />
                                    <img src='/assets/images/react-native.svg' className="mx-[20px]" alt="ReactNative" />
                                    <img src='/assets/images/nextjs.svg' className="mx-[20px]" alt="Nextjs" />
                                    <img src='/assets/images/angular.svg' className="mx-[20px]" alt="Angular" />
                                </Marquee>
                            </div>
                            <button className='text-[13px] text-black font-instrument underline' onClick={() => navigate("/our-works")}>
                                View our Works →
                            </button>
                        </section>

                        {/* Website Design Section */}
                        <section className="py-12 pb-8 px-4 border-t border-[#AB9E99]">
                            <h3 className="text-[15px] font-bold font-instrument mb-4 text-[#2C2421] border-l-6 border-[#FFD600] pl-[5px]">Our Website Design and Development Services</h3>
                            <Tag texts={['Prototype', 'UI/UX Design', 'Responsive', 'Design to Code']} />
                            <p className="mt-4 text-[14px] leading-[27px] text-[#000000] font-light font-instrument">
                                Our team specializes in designing and developing websites that resonate with your audience, showcasing your startup’s story while driving conversions.
                            </p>
                            <div className="flex items-center justify-between gap-8 py-[30px]">
                                <Marquee className='imageMarquee' autoFill={true}>
                                    <img src='/assets/images/WordPress.svg' className="mx-[20px]" alt="Reactjs" />
                                    <img src='/assets/images/Elementor.svg' className="mx-[20px]" alt="ReactNative" />
                                    <img src='/assets/images/webflow_logo.svg' className="mx-[20px]" alt="Nextjs" />
                                    <img src='/assets/images/wix.svg' className="mx-[20px]" alt="Angular" />
                                </Marquee>
                            </div>
                            <p className="mt-6 text-[15px] text-[#2C2421] font-light font-instrument">
                                Headless CMS/eCommerce Integration Expertise
                            </p>
                            <div className="flex items-center justify-between gap-8 py-[30px]">
                                <Marquee className='imageMarquee' autoFill={true}>
                                    <img src='/assets/images/Strapi.svg' className="mx-[20px]" alt="Reactjs" />
                                    <img src='/assets/images/medusa.svg' className="mx-[20px]" alt="ReactNative" />
                                    <img src='/assets/images/storyblok_logo.svg' className="mx-[20px]" alt="Nextjs" />
                                    <img src='/assets/images/contentful_logo.svg' className="mx-[20px]" alt="Angular" />
                                </Marquee>
                            </div>
                            <button className='text-[13px] text-black font-instrument underline' onClick={() => navigate("/our-works")}>
                                View our Works →
                            </button>
                        </section>

                        {/* Branding Services Section */}
                        <section className="py-12 pb-8 px-4 border-t border-b border-[#AB9E99]">
                            <h3 className="text-[15px] font-bold font-instrument mb-4 text-[#2C2421] border-l-6 border-[#FFD600] pl-[5px]">Our Branding Services</h3>
                            <Tag texts={['Logo Design', 'Brand Guidelines', 'Responsive', 'Visual Identity System']} />
                            <p className="mt-4 text-[14px] leading-[27px] text-[#000000] font-light font-instrument">
                                We help startups establish a strong brand presence with cohesive and memorable visual identities that set them apart in the market.
                            </p>
                            <button className='text-[13px] text-black font-instrument underline mt-[25px] block' onClick={() => navigate("/our-works")}>
                                View our Works →
                            </button>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AboutUsSection;