import React, { useEffect } from "react";
import './style.css'


export default function LogoImg() {
    useEffect(() => {
        // Adding the 'active' class after the component has mounted
        const svgElement = document.querySelector('svg');
        if (svgElement) {
            svgElement.classList.add('active');
        }
    }, []);
    return (
        <span className="MainLogo">
            <svg width="218" height="69" viewBox="0 0 218 69" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.7989 18.3313L16.0927 28.4783V48.7908L33.7927 58.9316L51.4927 48.7908V28.4783L33.7989 18.3313ZM46.4734 29.3115L33.7989 36.5823L21.1181 29.3115L33.7989 22.0469L46.4734 29.3115ZM19.3136 31.9902L32.1853 39.3782V54.2901L19.3136 46.9144V31.9902ZM35.4063 54.2901V39.3782L48.2841 31.9902V46.9083L35.4063 54.2901Z" fill="#FFD600" className="svg-elem-1"></path>
<path d="M33.7989 0L16.0927 10.1408V30.4533L33.7927 40.5942L51.4927 30.4533V10.1408L33.7989 0ZM46.418 10.9802L33.7373 18.251L21.0627 10.9802L33.7373 3.71563L46.418 10.9802ZM19.3136 13.6589L32.1853 21.047V35.9589L19.3136 28.577V13.6589ZM35.2708 35.7305L35.4001 21.0408L48.2779 13.6528L48.1486 28.3486L35.2708 35.7305Z" fill="#FFD600" className="svg-elem-2"></path>
<path d="M17.854 27.4475L0 37.6994V58.0119L17.7 68.1528L35.4001 58.0119L35.5541 37.5883L17.854 27.4475ZM30.3808 38.5388L17.7 45.8096L5.02548 38.5388L17.7062 31.2742L30.3808 38.5388ZM3.22099 41.2175L16.0988 48.6056V63.5175L3.22099 56.1418V41.2175ZM19.3136 63.5175V48.6056L32.1914 41.2175V56.1356L19.3136 63.5175Z" fill="#FFD600" className="svg-elem-3"></path>
<path d="M71.9917 29.9695C70.164 29.9695 68.3784 29.7678 66.6348 29.3644C64.9122 28.9398 63.4942 28.3773 62.3809 27.6767L64.7757 22.1996C65.8261 22.8152 67.0025 23.3141 68.3049 23.6962C69.6074 24.0571 70.8573 24.2376 72.0547 24.2376C73.0841 24.2376 73.8193 24.1421 74.2605 23.951C74.7016 23.7387 74.9222 23.4309 74.9222 23.0275C74.9222 22.5605 74.6281 22.2102 74.0399 21.9767C73.4727 21.7432 72.5274 21.4884 71.2039 21.2124C69.5023 20.8515 68.0843 20.4694 66.9499 20.0661C65.8155 19.6415 64.8282 18.9622 63.9879 18.0281C63.1476 17.0728 62.7275 15.7884 62.7275 14.175C62.7275 12.7739 63.1161 11.5001 63.8934 10.3537C64.6707 9.20738 65.8261 8.30514 67.3596 7.64703C68.9141 6.98893 70.7943 6.65988 73 6.65988C74.5125 6.65988 75.9936 6.82971 77.4431 7.16938C78.9136 7.48782 80.2055 7.96547 81.3189 8.60235L79.0816 14.0476C76.9179 12.9437 74.8697 12.3917 72.937 12.3917C71.0253 12.3917 70.0695 12.8588 70.0695 13.7929C70.0695 14.2387 70.3531 14.5783 70.9203 14.8119C71.4875 15.0242 72.4223 15.2577 73.7248 15.5124C75.4054 15.8309 76.8233 16.2024 77.9787 16.627C79.1341 17.0303 80.132 17.699 80.9723 18.6331C81.8336 19.5672 82.2642 20.8409 82.2642 22.4543C82.2642 23.8555 81.8756 25.1292 81.0983 26.2756C80.3211 27.4007 79.1552 28.303 77.6006 28.9823C76.0671 29.6404 74.1974 29.9695 71.9917 29.9695Z" fill="#2C2421" className="svg-elem-4"></path>
<path d="M88.9591 12.9968H82.4993V7.16938H102.855V12.9968H96.3956V29.46H88.9591V12.9968Z" fill="#2C2421" className="svg-elem-5"></path>
<path d="M115.035 29.9695C111.653 29.9695 109.017 29.046 107.126 27.1991C105.235 25.3521 104.29 22.7622 104.29 19.4292V7.16938H111.727V19.2063C111.727 20.8409 112.01 22.0298 112.577 22.7728C113.166 23.5158 114.006 23.8873 115.098 23.8873C116.191 23.8873 117.02 23.5158 117.588 22.7728C118.176 22.0298 118.47 20.8409 118.47 19.2063V7.16938H125.78V19.4292C125.78 22.7622 124.835 25.3521 122.945 27.1991C121.054 29.046 118.417 29.9695 115.035 29.9695Z" fill="#2C2421" className="svg-elem-6"></path>
<path d="M129.189 7.16938H140.061C142.518 7.16938 144.682 7.62581 146.552 8.53866C148.442 9.43028 149.902 10.7146 150.932 12.3917C151.982 14.0688 152.507 16.0432 152.507 18.3147C152.507 20.5862 151.982 22.5605 150.932 24.2376C149.902 25.9147 148.442 27.2097 146.552 28.1225C144.682 29.0141 142.518 29.46 140.061 29.46H129.189V7.16938ZM139.745 23.6007C141.342 23.6007 142.613 23.1443 143.558 22.2314C144.525 21.2974 145.008 19.9918 145.008 18.3147C145.008 16.6376 144.525 15.3426 143.558 14.4297C142.613 13.4957 141.342 13.0286 139.745 13.0286H136.626V23.6007H139.745Z" fill="#2C2421" className="svg-elem-7"></path>
<path d="M155.223 7.16938H162.659V29.46H155.223V7.16938Z" fill="#2C2421" className="svg-elem-8"></path>
<path d="M177.804 29.9695C175.43 29.9695 173.298 29.4706 171.407 28.4728C169.516 27.475 168.035 26.0951 166.964 24.3331C165.893 22.5499 165.357 20.5437 165.357 18.3147C165.357 16.0856 165.893 14.0901 166.964 12.3281C168.035 10.5448 169.516 9.1543 171.407 8.15653C173.298 7.15877 175.43 6.65988 177.804 6.65988C180.178 6.65988 182.31 7.15877 184.2 8.15653C186.091 9.1543 187.572 10.5448 188.643 12.3281C189.715 14.0901 190.251 16.0856 190.251 18.3147C190.251 20.5437 189.715 22.5499 188.643 24.3331C187.572 26.0951 186.091 27.475 184.2 28.4728C182.31 29.4706 180.178 29.9695 177.804 29.9695ZM177.804 23.8873C178.728 23.8873 179.558 23.6644 180.293 23.2186C181.049 22.7516 181.648 22.1041 182.089 21.2761C182.53 20.427 182.751 19.4398 182.751 18.3147C182.751 17.1895 182.53 16.213 182.089 15.385C181.648 14.5359 181.049 13.8884 180.293 13.4426C179.558 12.9755 178.728 12.742 177.804 12.742C176.879 12.742 176.039 12.9755 175.283 13.4426C174.548 13.8884 173.959 14.5359 173.518 15.385C173.077 16.213 172.857 17.1895 172.857 18.3147C172.857 19.4398 173.077 20.427 173.518 21.2761C173.959 22.1041 174.548 22.7516 175.283 23.2186C176.039 23.6644 176.879 23.8873 177.804 23.8873Z" fill="#2C2421" className="svg-elem-9"></path>
<path d="M218 32.2434C217.226 33.1768 216.296 33.8768 215.208 34.3435C214.121 34.8314 212.918 35.0754 211.601 35.0754C210.283 35.0754 209.102 34.9057 208.056 34.5662C207.01 34.248 205.965 33.6965 204.919 32.9116C203.874 32.1479 202.681 31.0554 201.343 29.6341C199.503 29.2523 197.882 28.531 196.481 27.4703C195.08 26.3884 193.982 25.0626 193.187 23.4928C192.413 21.923 192.026 20.1941 192.026 18.3061C192.026 16.0787 192.56 14.0846 193.626 12.3239C194.693 10.542 196.167 9.15247 198.049 8.15543C199.931 7.1584 202.054 6.65988 204.417 6.65988C206.78 6.65988 208.903 7.1584 210.785 8.15543C212.667 9.15247 214.142 10.542 215.208 12.3239C216.275 14.0846 216.808 16.0787 216.808 18.3061C216.808 20.7456 216.17 22.9094 214.894 24.7974C213.619 26.6642 211.894 28.0431 209.719 28.9341C210.032 29.2735 210.346 29.5068 210.66 29.6341C210.994 29.7826 211.36 29.8568 211.758 29.8568C212.845 29.8568 213.859 29.3795 214.8 28.4249L218 32.2434ZM204.417 12.7375C203.497 12.7375 202.661 12.9709 201.908 13.4376C201.176 13.8831 200.59 14.5301 200.151 15.3786C199.712 16.206 199.492 17.1818 199.492 18.3061C199.492 19.4304 199.712 20.4168 200.151 21.2654C200.59 22.0927 201.176 22.7397 201.908 23.2064C202.661 23.6519 203.497 23.8746 204.417 23.8746C205.337 23.8746 206.163 23.6519 206.895 23.2064C207.648 22.7397 208.244 22.0927 208.683 21.2654C209.123 20.4168 209.342 19.4304 209.342 18.3061C209.342 17.1818 209.123 16.206 208.683 15.3786C208.244 14.5301 207.648 13.8831 206.895 13.4376C206.163 12.9709 205.337 12.7375 204.417 12.7375Z" fill="#2C2421" className="svg-elem-10"></path>
<path d="M63.2688 38.2578H64.5471V46.2208H69.3918V47.351H63.2688V38.2578Z" fill="#2C2421" className="svg-elem-11"></path>
<path d="M76.7153 43.9475C76.7153 44.0428 76.7068 44.1684 76.6898 44.3243H71.1931C71.2698 44.9305 71.5297 45.4198 71.9729 45.7922C72.4245 46.1559 72.9827 46.3378 73.6474 46.3378C74.457 46.3378 75.1089 46.0606 75.6032 45.5064L76.2807 46.3118C75.9739 46.6755 75.5904 46.9526 75.1302 47.1432C74.6786 47.3337 74.1715 47.4289 73.6091 47.4289C72.8932 47.4289 72.2584 47.2817 71.7044 46.9873C71.1505 46.6842 70.7202 46.2641 70.4134 45.7272C70.1151 45.1903 69.966 44.5841 69.966 43.9086C69.966 43.2417 70.1108 42.6398 70.4006 42.1029C70.6989 41.566 71.1036 41.1503 71.615 40.8558C72.1348 40.5527 72.7185 40.4012 73.3662 40.4012C74.0139 40.4012 74.5891 40.5527 75.0919 40.8558C75.6032 41.1503 75.9995 41.566 76.2807 42.1029C76.5704 42.6398 76.7153 43.2547 76.7153 43.9475ZM73.3662 41.4534C72.7782 41.4534 72.2839 41.6352 71.8834 41.999C71.4914 42.3627 71.2613 42.839 71.1931 43.4279H75.5393C75.4711 42.8477 75.2368 42.3757 74.8362 42.012C74.4442 41.6396 73.9542 41.4534 73.3662 41.4534Z" fill="#2C2421" className="svg-elem-12"></path>
<path d="M82.2286 46.9483C82.0497 47.1042 81.8281 47.2254 81.5639 47.312C81.2997 47.39 81.027 47.4289 80.7458 47.4289C80.0641 47.4289 79.5357 47.2428 79.1607 46.8704C78.7858 46.498 78.5983 45.9654 78.5983 45.2725V41.4924H77.4478V40.4661H78.5983V38.9592H79.8254V40.4661H81.7684V41.4924H79.8254V45.2206C79.8254 45.593 79.9149 45.8788 80.0939 46.0779C80.2814 46.2771 80.5455 46.3767 80.8864 46.3767C81.2614 46.3767 81.581 46.2685 81.8451 46.052L82.2286 46.9483Z" fill="#2C2421" className="svg-elem-13"></path>
<path d="M84.019 37.6342C84.2576 37.6342 84.4536 37.7208 84.607 37.894C84.769 38.0586 84.8499 38.2664 84.8499 38.5176C84.8499 38.6475 84.8371 38.7774 84.8116 38.9073C84.786 39.0285 84.7306 39.206 84.6454 39.4399L84.0318 41.2585H83.2265L83.6995 39.323C83.5375 39.2623 83.4054 39.1627 83.3032 39.0242C83.2094 38.877 83.1626 38.7081 83.1626 38.5176C83.1626 38.2578 83.2435 38.0456 83.4054 37.881C83.5674 37.7165 83.7719 37.6342 84.019 37.6342Z" fill="#2C2421" className="svg-elem-14"></path>
<path d="M88.3391 47.4289C87.7852 47.4289 87.2526 47.3553 86.7413 47.2081C86.23 47.0522 85.8294 46.8574 85.5397 46.6235L86.051 45.6363C86.3493 45.8528 86.7114 46.026 87.1375 46.1559C87.5636 46.2858 87.994 46.3507 88.4286 46.3507C89.5024 46.3507 90.0393 46.039 90.0393 45.4154C90.0393 45.2076 89.9668 45.043 89.8219 44.9218C89.6771 44.8006 89.4939 44.714 89.2723 44.662C89.0592 44.6014 88.7524 44.5364 88.3519 44.4671C87.8065 44.3805 87.3591 44.2809 87.0097 44.1684C86.6688 44.0558 86.3748 43.8653 86.1277 43.5968C85.8806 43.3283 85.757 42.9516 85.757 42.4666C85.757 41.8431 86.0127 41.3451 86.524 40.9727C87.0353 40.5917 87.7213 40.4012 88.582 40.4012C89.0337 40.4012 89.4853 40.4575 89.937 40.57C90.3887 40.6826 90.7594 40.8342 91.0491 41.0247L90.525 42.012C89.9711 41.6482 89.3192 41.4664 88.5692 41.4664C88.0494 41.4664 87.6531 41.553 87.3804 41.7262C87.1077 41.8994 86.9714 42.1289 86.9714 42.4147C86.9714 42.6398 87.0481 42.8174 87.2015 42.9473C87.3549 43.0772 87.5423 43.1724 87.7639 43.2331C87.994 43.2937 88.3136 43.363 88.7226 43.4409C89.268 43.5362 89.7069 43.6401 90.0393 43.7527C90.3801 43.8566 90.6699 44.0385 90.9085 44.2983C91.1471 44.5581 91.2664 44.9218 91.2664 45.3895C91.2664 46.013 91.0022 46.511 90.4739 46.8833C89.954 47.2471 89.2425 47.4289 88.3391 47.4289Z" fill="#2C2421" className="svg-elem-15"></path>
<path d="M102.433 42.6355C102.952 42.7827 103.362 43.0512 103.66 43.4409C103.958 43.822 104.107 44.3069 104.107 44.8958C104.107 45.6839 103.813 46.2901 103.225 46.7145C102.646 47.1388 101.798 47.351 100.681 47.351H96.4119V38.2578H100.426C101.448 38.2578 102.237 38.4656 102.791 38.8813C103.353 39.297 103.634 39.8729 103.634 40.609C103.634 41.0853 103.523 41.4967 103.302 41.8431C103.089 42.1895 102.799 42.4536 102.433 42.6355ZM97.6902 39.31V42.2198H100.311C100.967 42.2198 101.47 42.0986 101.819 41.8561C102.177 41.6049 102.356 41.2412 102.356 40.7649C102.356 40.2886 102.177 39.9292 101.819 39.6867C101.47 39.4355 100.967 39.31 100.311 39.31H97.6902ZM100.63 46.2988C101.355 46.2988 101.9 46.1775 102.266 45.9351C102.633 45.6926 102.816 45.3115 102.816 44.7919C102.816 43.7787 102.088 43.272 100.63 43.272H97.6902V46.2988H100.63Z" fill="#2C2421" className="svg-elem-16"></path>
<path d="M112.222 40.4661V47.351H111.059V46.3118C110.812 46.6668 110.484 46.944 110.075 47.1432C109.674 47.3337 109.235 47.4289 108.758 47.4289C107.855 47.4289 107.143 47.1778 106.623 46.6755C106.103 46.1645 105.844 45.4154 105.844 44.4282V40.4661H107.071V44.2853C107.071 44.9521 107.228 45.4587 107.544 45.8052C107.859 46.1429 108.311 46.3118 108.899 46.3118C109.546 46.3118 110.058 46.1126 110.433 45.7142C110.808 45.3158 110.995 44.7529 110.995 44.0255V40.4661H112.222Z" fill="#2C2421" className="svg-elem-17"></path>
<path d="M114.558 40.4661H115.786V47.351H114.558V40.4661ZM115.172 39.1411C114.933 39.1411 114.733 39.0632 114.571 38.9073C114.418 38.7514 114.341 38.5609 114.341 38.3357C114.341 38.1105 114.418 37.92 114.571 37.7641C114.733 37.5996 114.933 37.5173 115.172 37.5173C115.411 37.5173 115.607 37.5952 115.76 37.7511C115.922 37.8984 116.003 38.0845 116.003 38.3097C116.003 38.5435 115.922 38.7427 115.76 38.9073C115.607 39.0632 115.411 39.1411 115.172 39.1411Z" fill="#2C2421" className="svg-elem-18"></path>
<path d="M118.129 37.7122H119.356V47.351H118.129V37.7122Z" fill="#2C2421" className="svg-elem-19"></path>
<path d="M128.09 37.7122V47.351H126.914V46.2598C126.642 46.6409 126.296 46.931 125.879 47.1302C125.461 47.3294 125.001 47.4289 124.498 47.4289C123.842 47.4289 123.254 47.2817 122.734 46.9873C122.214 46.6928 121.805 46.2815 121.507 45.7532C121.217 45.2163 121.072 44.6014 121.072 43.9086C121.072 43.2157 121.217 42.6052 121.507 42.0769C121.805 41.5486 122.214 41.1373 122.734 40.8428C123.254 40.5484 123.842 40.4012 124.498 40.4012C124.984 40.4012 125.431 40.4964 125.84 40.687C126.25 40.8688 126.59 41.1416 126.863 41.5053V37.7122H128.09ZM124.601 46.3378C125.027 46.3378 125.414 46.2382 125.764 46.039C126.113 45.8311 126.386 45.5453 126.582 45.1816C126.778 44.8092 126.876 44.3849 126.876 43.9086C126.876 43.4322 126.778 43.0122 126.582 42.6485C126.386 42.2761 126.113 41.9903 125.764 41.7911C125.414 41.5919 125.027 41.4924 124.601 41.4924C124.166 41.4924 123.774 41.5919 123.424 41.7911C123.084 41.9903 122.811 42.2761 122.606 42.6485C122.41 43.0122 122.312 43.4322 122.312 43.9086C122.312 44.3849 122.41 44.8092 122.606 45.1816C122.811 45.5453 123.084 45.8311 123.424 46.039C123.774 46.2382 124.166 46.3378 124.601 46.3378Z" fill="#2C2421" className="svg-elem-20"></path>
<path d="M135.801 39.3879H132.733V38.2578H140.134V39.3879H137.066V47.351H135.801V39.3879Z" fill="#2C2421" className="svg-elem-21"></path>
<path d="M143.55 47.4289C142.877 47.4289 142.272 47.2774 141.735 46.9743C141.198 46.6712 140.776 46.2555 140.469 45.7272C140.171 45.1903 140.022 44.5841 140.022 43.9086C140.022 43.2331 140.171 42.6312 140.469 42.1029C140.776 41.566 141.198 41.1503 141.735 40.8558C142.272 40.5527 142.877 40.4012 143.55 40.4012C144.223 40.4012 144.824 40.5527 145.353 40.8558C145.889 41.1503 146.307 41.566 146.605 42.1029C146.912 42.6312 147.065 43.2331 147.065 43.9086C147.065 44.5841 146.912 45.1903 146.605 45.7272C146.307 46.2555 145.889 46.6712 145.353 46.9743C144.824 47.2774 144.223 47.4289 143.55 47.4289ZM143.55 46.3378C143.985 46.3378 144.372 46.2382 144.713 46.039C145.063 45.8311 145.335 45.5453 145.531 45.1816C145.727 44.8092 145.825 44.3849 145.825 43.9086C145.825 43.4322 145.727 43.0122 145.531 42.6485C145.335 42.2761 145.063 41.9903 144.713 41.7911C144.372 41.5919 143.985 41.4924 143.55 41.4924C143.115 41.4924 142.723 41.5919 142.374 41.7911C142.033 41.9903 141.761 42.2761 141.556 42.6485C141.36 43.0122 141.262 43.4322 141.262 43.9086C141.262 44.3849 141.36 44.8092 141.556 45.1816C141.761 45.5453 142.033 45.8311 142.374 46.039C142.723 46.2382 143.115 46.3378 143.55 46.3378Z" fill="#2C2421" className="svg-elem-22"></path>
<path d="M155.243 40.4661V46.4157C155.243 47.6195 154.949 48.5071 154.361 49.0787C153.773 49.659 152.896 49.9491 151.728 49.9491C151.089 49.9491 150.48 49.8581 149.9 49.6763C149.321 49.5031 148.852 49.2519 148.494 48.9228L149.082 47.9615C149.397 48.2387 149.785 48.4552 150.245 48.6111C150.714 48.7756 151.196 48.8579 151.69 48.8579C152.482 48.8579 153.066 48.6674 153.441 48.2863C153.825 47.9053 154.016 47.325 154.016 46.5456V46C153.727 46.3551 153.369 46.6235 152.943 46.8054C152.525 46.9786 152.069 47.0652 151.575 47.0652C150.927 47.0652 150.339 46.9267 149.811 46.6495C149.291 46.3637 148.882 45.9697 148.584 45.4674C148.285 44.9564 148.136 44.3762 148.136 43.7267C148.136 43.0772 148.285 42.5013 148.584 41.999C148.882 41.488 149.291 41.094 149.811 40.8169C150.339 40.5397 150.927 40.4012 151.575 40.4012C152.086 40.4012 152.559 40.4964 152.994 40.687C153.437 40.8775 153.799 41.1589 154.08 41.5313V40.4661H155.243ZM151.715 45.974C152.159 45.974 152.555 45.8788 152.904 45.6882C153.262 45.4977 153.539 45.2336 153.735 44.8958C153.94 44.5494 154.042 44.1597 154.042 43.7267C154.042 43.0599 153.825 42.5229 153.39 42.1159C152.955 41.7002 152.397 41.4924 151.715 41.4924C151.025 41.4924 150.463 41.7002 150.028 42.1159C149.593 42.5229 149.376 43.0599 149.376 43.7267C149.376 44.1597 149.474 44.5494 149.67 44.8958C149.875 45.2336 150.152 45.4977 150.501 45.6882C150.859 45.8788 151.264 45.974 151.715 45.974Z" fill="#2C2421" className="svg-elem-23"></path>
<path d="M163.711 43.9475C163.711 44.0428 163.703 44.1684 163.686 44.3243H158.189C158.266 44.9305 158.526 45.4198 158.969 45.7922C159.42 46.1559 159.979 46.3378 160.643 46.3378C161.453 46.3378 162.105 46.0606 162.599 45.5064L163.277 46.3118C162.97 46.6755 162.586 46.9526 162.126 47.1432C161.674 47.3337 161.167 47.4289 160.605 47.4289C159.889 47.4289 159.254 47.2817 158.7 46.9873C158.146 46.6842 157.716 46.2641 157.409 45.7272C157.111 45.1903 156.962 44.5841 156.962 43.9086C156.962 43.2417 157.107 42.6398 157.396 42.1029C157.695 41.566 158.1 41.1503 158.611 40.8558C159.131 40.5527 159.714 40.4012 160.362 40.4012C161.01 40.4012 161.585 40.5527 162.088 40.8558C162.599 41.1503 162.995 41.566 163.277 42.1029C163.566 42.6398 163.711 43.2547 163.711 43.9475ZM160.362 41.4534C159.774 41.4534 159.28 41.6352 158.879 41.999C158.487 42.3627 158.257 42.839 158.189 43.4279H162.535C162.467 42.8477 162.233 42.3757 161.832 42.012C161.44 41.6396 160.95 41.4534 160.362 41.4534Z" fill="#2C2421" className="svg-elem-24"></path>
<path d="M169.224 46.9483C169.046 47.1042 168.824 47.2254 168.56 47.312C168.296 47.39 168.023 47.4289 167.742 47.4289C167.06 47.4289 166.532 47.2428 166.157 46.8704C165.782 46.498 165.594 45.9654 165.594 45.2725V41.4924H164.444V40.4661H165.594V38.9592H166.821V40.4661H168.764V41.4924H166.821V45.2206C166.821 45.593 166.911 45.8788 167.09 46.0779C167.277 46.2771 167.541 46.3767 167.882 46.3767C168.257 46.3767 168.577 46.2685 168.841 46.052L169.224 46.9483Z" fill="#2C2421" className="svg-elem-25"></path>
<path d="M174.326 40.4012C175.186 40.4012 175.868 40.6566 176.371 41.1676C176.882 41.6699 177.138 42.4103 177.138 43.3889V47.351H175.911V43.5318C175.911 42.865 175.753 42.3627 175.438 42.025C175.122 41.6872 174.671 41.5183 174.083 41.5183C173.418 41.5183 172.894 41.7175 172.51 42.1159C172.127 42.5056 171.935 43.0685 171.935 43.8046V47.351H170.708V37.7122H171.935V41.4404C172.191 41.1113 172.523 40.8558 172.932 40.674C173.35 40.4921 173.814 40.4012 174.326 40.4012Z" fill="#2C2421" className="svg-elem-26"></path>
<path d="M185.569 43.9475C185.569 44.0428 185.561 44.1684 185.544 44.3243H180.047C180.124 44.9305 180.384 45.4198 180.827 45.7922C181.279 46.1559 181.837 46.3378 182.501 46.3378C183.311 46.3378 183.963 46.0606 184.457 45.5064L185.135 46.3118C184.828 46.6755 184.444 46.9526 183.984 47.1432C183.533 47.3337 183.026 47.4289 182.463 47.4289C181.747 47.4289 181.112 47.2817 180.559 46.9873C180.005 46.6842 179.574 46.2641 179.267 45.7272C178.969 45.1903 178.82 44.5841 178.82 43.9086C178.82 43.2417 178.965 42.6398 179.255 42.1029C179.553 41.566 179.958 41.1503 180.469 40.8558C180.989 40.5527 181.573 40.4012 182.22 40.4012C182.868 40.4012 183.443 40.5527 183.946 40.8558C184.457 41.1503 184.854 41.566 185.135 42.1029C185.424 42.6398 185.569 43.2547 185.569 43.9475ZM182.22 41.4534C181.632 41.4534 181.138 41.6352 180.737 41.999C180.345 42.3627 180.115 42.839 180.047 43.4279H184.393C184.325 42.8477 184.091 42.3757 183.69 42.012C183.298 41.6396 182.808 41.4534 182.22 41.4534Z" fill="#2C2421" className="svg-elem-27"></path>
<path d="M188.449 41.6223C188.662 41.2239 188.978 40.9208 189.395 40.7129C189.813 40.5051 190.32 40.4012 190.917 40.4012V41.6093C190.848 41.6006 190.755 41.5963 190.635 41.5963C189.971 41.5963 189.446 41.7998 189.063 42.2068C188.688 42.6052 188.501 43.1768 188.501 43.9215V47.351H187.273V40.4661H188.449V41.6223Z" fill="#2C2421" className="svg-elem-28"></path>
</svg>

        </span>
    );
}
