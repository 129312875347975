import React from "react";
import { Link ,useNavigate} from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full">
      <div className="container">
    <div className="bg-[#2c2421] text-white py-[35px] px-[50px] bg-cover bg-no-repeat bg-center" style={{backgroundImage: "url('/assets/images/footer-bg.svg')"}}>
      <div className="max-w-7xl mx-auto grid grid-cols-12 gap-8">
        {/* Left Column */}
        <div className="col-span-3 flex flex-col text-[14px] text-[#fff ] font-regular font-instrument leading-[36px]">
          <p>Wireframing</p>
          <p>UI/UX Design</p>
          <p>Prototyping</p>
          <p>Frontend Development</p>
          <p>Mobile App Development</p>
          <p>Responsive Web Design</p>
          <p>Interaction Design</p>
        </div>

        {/* Center Column */}
        <div className="col-span-6 text-center">
          <h2 className="text-[38px] text-[#FFD600] text-4xl font-regular mb-4">
            Let’s Bring Your <br/> <span className="text-[38px] font-[Nyght] font-extrabold italic">Vision to Life</span>
          </h2>
          <p className="text-lg mb-[26px]">
            Your dream product is just a step away.<br/>
            Partner with us to turn your ideas into <br/> impactful solutions.
          </p>
          <button className="bg-[#FFD600] hover:bg-[#FFD600] text-[#2C2421] text-[16px] font-bold font-instrument py-[13px] px-[40px]"  onClick={() => navigate("/contact-us")}>
            Contact us now
          </button>
        </div>

        {/* Right Column */}
        <div className="col-span-3 flex flex-col text-[14px] text-[#fff ] font-regular font-instrument leading-[36px] justify-end items-end">
          <p>Headless CMS Integration</p>
          <p>Product Strategy</p>
          <p>MVP Development</p>
          <p>Design System Creation</p>
          <p>Usability Testing</p>
          <p>Performance Optimization</p>
          <p>Branding & Visual Identity</p>
        </div>
      </div>
    </div>
    <div className="w-full flex content-center justify-between py-[20px]">
      <nav class="flex font-instrument">
            <Link to="/services" className="text-[#000000] text-[15px] after:mx-2 after:content-['|']">
              Services
            </Link>
            <Link to="/our-works" className="text-[#000000] text-[15px] after:mx-2 after:content-['|']">
              Our Works
            </Link>
            <Link to="/why-us" className="text-[#000000] text-[15px] after:mx-2 after:content-['|']">
            Why us
            </Link>
            <a href="mailto:hr@studioq.co.in" className="text-[#000000] text-[15px] after:mx-2 after:content-['|']">
              Careers
            </a>
            <Link to="/contact-us" className="text-[#000000] text-[15px] ">
              Contact us
            </Link>
          </nav>
          <div className="text-[#000000] text-[15px] font-instrument">
          Copyright © 2025 StudioQ Web Solutions All rights reserved.
            </div>
      </div>
    </div>
    </div>
  );
};

export default Footer;
