import React from "react";
import ShuffleTextEffect from "../../components/text-ani";
import Button from "../../components/ui/button";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


const ServicePage = () => {
const navigate = useNavigate();

  return (
    <div className="w-full mb-[40px]">
      <Helmet>
        <title>Our Services – UI/UX, Website & Mobile App Development | StudioQ</title>
      </Helmet>
      <div className="container">
        <div className="grid gap-[50px] gap-y-[35px] grid-cols-12">
          <div className="col-span-6">
            <h1 className="font-regular  text-[33px] text-black leading-tight">
              Comprehensive Digital Solutions <br /> Tailored for Your
              <ShuffleTextEffect className="text-black text-[46px] font-[Nyght] font-extrabold italic relative bg-[#FFD600]" text="Success"/>
            </h1>
            <p className="mt-[33px] text-[17px] text-[#2C2421] font-light font-montserrat">
              From <span className="font-semibold">strategy to execution</span>, we offer comprehensive, end-to-end services designed to turn your ideas into impactful, market-ready products. Our team specializes in crafting seamless user experiences, developing scalable and robust web and mobile applications, and creating memorable brand identities that resonate with your audience. Whether you’re launching a new product or enhancing an existing one, our expertise ensures exceptional results, tailored to your unique needs, at every stage of the process.
            </p>
            <p className="mt-3 text-[17px] text-[#2C2421] font-light font-montserrat">
              Dive into our core service offerings below to discover how we can collaborate to bring your vision to life and drive meaningful success.
            </p>
            <div className="mt-[40px]">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-[75px]">
                <div className='flex flex-col items-start'>
                  <img src='/assets/images/product.svg' className="mb-[10px]" alt="ProductIcon" />
                  <h3 className="text-[17px] text-[#2C2421] font-medium font-instrument leading-[20px]">Product Vision & Strategy</h3>
                </div>
                <div className='flex flex-col items-start'>
                  <img src='/assets/images/uiux.svg' className="mb-[10px]" alt="ProductIcon" />
                  <h3 className="text-[17px] text-[#2C2421] font-medium font-instrument leading-[20px]">UI/UX Design <br /> Excellence</h3>
                </div>
                <div className='flex flex-col items-start'>
                  <img src='/assets/images/design.svg' className="mb-[10px]" alt="ProductIcon" />
                  <h3 className="text-[17px] text-[#2C2421] font-medium font-instrument leading-[20px]">DesignOps <br /> Solutions</h3>
                </div>
                <div className='flex flex-col items-start'>
                  <img src='/assets/images/web-mobile-icon.svg' className="mb-[10px]" alt="ProductIcon" />
                  <h3 className="text-[17px] text-[#2C2421] font-medium font-instrument leading-[20px]">Web & Mobile <br /> Apps</h3>
                </div>
                <div className='flex flex-col items-start'>
                  <img src='/assets/images/code-icon.svg' className="mb-[10px]" alt="ProductIcon" />
                  <h3 className="text-[17px] text-[#2C2421] font-medium font-instrument leading-[20px]">Website Design  <br />& Development </h3>
                </div>
                <div className='flex flex-col items-start'>
                  <img src='/assets/images/branding-icon.svg' className="mb-[10px]" alt="ProductIcon" />
                  <h3 className="text-[17px] text-[#2C2421] font-medium font-instrument leading-[20px]">Branding <br /> Services</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-6">
            <div className='col-span-6 flex flex-col justify-end mt-[-80px] relative'>

              {/* <div className='absolute left-0 top-[-90px] w-full h-[70%] bg-cover bg-no-repeat [background-position:top_0_right_0]' style={{backgroundImage: "url('/assets/images/bg-logo.svg')"}}></div> */}
              <div className="w-full min-h-[588px]">
              <img src='/assets/images/hr-img.svg' className="" alt="Reactjs" />
              </div>
              <h1 className="font-regular text-[33px] text-[#2C2421] leading-tight block">
                Partner with us for innovative designs and robust development solutions that elevate your digital presence.
              </h1>
              <div className='flex items-center gap-2 justify-between my-[30px]'>
                <Button type="filled"  onClick={() => navigate("/contact-us")}>
                  Let’s Build Your Project Together
                </Button>
                <Button type="outline" className="btn-borer-hover px-[50px]" onClick={() => navigate("/our-works")}>
                <span>Explore Our Portfolio</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <section className="w-full mt-[60px]">
          <div className="grid gap-[40px] grid-cols-12">
            {/* Section 1 */}
            <div className="col-span-12 border border-[#2C2421]">
              <div className="grid grid-cols-12">
                <div className="col-span-6 overflow-hidden">
                <div
                  className="w-full h-full bg-cover bg-no-repeat bg-center transform transition-transform duration-300 hover:scale-110"
                  style={{ backgroundImage: "url('/assets/images/product-bg.svg')" }}
                >
                    {/* <img src='/assets/images/product-bg.svg' className="" alt="Reactjs" /> */}
                  </div>
                </div>
                <div className="col-span-6 px-[40px] py-[40px]">
                  <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px]">Product Vision & Strategy</h3>
                  <p className="text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[17px]">Building a successful product starts with a well-defined vision and a strategic roadmap.</p>
                  <div className="grid grid-cols-1 gap-[20px] sm:grid-cols-2 gap-y-[40px] text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[30px]">
                    <p>
                      <b>Ideation Workshops:</b> Brainstorming and refining ideas to identify viable solutions and opportunities that address real-world problems.
                    </p>
                    <p>
                      <b>Competitor & User Research:</b> Conducting in-depth research to analyze competitors and understand user needs, behaviors, and preferences.
                    </p>
                    <p>
                      <b>MVP Planning:</b> Defining the minimum viable product to validate your idea and enter the market faster.
                    </p>
                    <p>
                      <b>Information Architecture:</b> Structuring and organizing content and features for intuitive navigation and usability.
                    </p>
                    <p>
                      <b>Feature Definition:</b> Defining key product features based on user needs and business goals to prioritize what matters most.
                    </p>
                    <p>
                      <b>Roadmap Development:</b> Creating a detailed, step-by-step plan to guide the product development process and align all stakeholders.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Section 2 */}
            <div className="col-span-6 border border-[#2C2421]">
              <div className="grid grid-cols-12 h-full">
                <div className="col-span-12 px-[40px] py-[40px]">
                  <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px]">UI/UX Design</h3>
                  <p className="text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[17px]">We specialize in creating intuitive, user-friendly designs that not only look great but also deliver a flawless user experience.</p>
                  <div className="grid grid-cols-1 gap-[20px] sm:grid-cols-2 gap-y-[40px] text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[30px]">
                    <p>
                      <b>User Journey Mapping:</b> Visualizing user interactions to identify pain points and enhance usability.
                    </p>
                    <p>
                      <b>Wireframes & Prototypes:</b> Creating detailed blueprints for layout and functionality.
                    </p>
                    <p>
                      <b>Interaction Design:</b> Designing intuitive and engaging interactions for seamless navigation.
                    </p>
                    <p>
                      <b>Design System Creation:</b> Developing reusable design components for consistency and scalability.
                    </p>
                    <p>
                      <b>Usability Testing:</b> Validating designs through real user feedback to ensure effectiveness.
                    </p>
                    <p>
                      <b>Visual Design:</b> Building aesthetically pleasing and brand-aligned interfaces.
                    </p>
                  </div>
                </div>
                <div className="col-span-12 flex items-end overflow-hidden">
                  <img src='/assets/images/ui-ux.svg' className="w-full h-full bg-cover bg-no-repeat bg-center transform transition-transform duration-300 hover:scale-110" alt="Reactjs" />
                </div>
              </div>
            </div>
            {/* Section 3 */}
            <div className="col-span-6 border border-[#2C2421]">
              <div className="grid grid-cols-12">
                <div className="col-span-12 overflow-hidden">
                  <img src='/assets/images/designOps.svg' className="w-full h-full bg-cover bg-no-repeat bg-center transform transition-transform duration-300 hover:scale-110" alt="Reactjs" />
                </div>
                <div className="col-span-12 px-[40px] py-[40px]">
                  <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px]">DesignOps Solutions</h3>
                  <p className="text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[17px]">We streamline the design process with operational strategies that prioritize collaboration, efficiency, and scalability.</p>
                  <div className="grid grid-cols-1 gap-[20px] sm:grid-cols-2 gap-y-[40px] text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[30px]">
                    <p>
                      <b>Design Process Optimization:</b> Streamlined workflows for better efficiency and collaboration.
                    </p>
                    <p>
                      <b>Tool Integration:</b> Implementing tools to enhance productivity and communication.
                    </p>
                    <p>
                      <b>Collaboration Frameworks:</b> Aligning cross-functional teams to work cohesively.
                    </p>
                    <p>
                      <b>Design System Maintenance:</b> Updating and managing design systems for consistency.
                    </p>
                    <p>
                      <b>Scalable Design Processes:</b> Creating workflows that grow with your team and projects.
                    </p>
                    <p>
                      <b>Metrics & Feedback Loops:</b> Establishing metrics to track design success and improve continuously.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Section 4 */}
            <div className="col-span-12 border border-[#2C2421]">
              <div className="grid grid-cols-12">
                <div className="col-span-6 px-[40px] py-[40px]">
                  <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px]">Web & Mobile App Services</h3>
                  <p className="text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[17px]">From MVPs to fully-featured applications, our solutions are robust, user-friendly, and performance-driven.</p>
                  <div className="grid grid-cols-1 gap-[20px] sm:grid-cols-2 gap-y-[40px] text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[30px]">
                    <p>
                      <b>Web Application Development:</b> Tailored web apps built to address specific business needs.
                    </p>
                    <p>
                      <b>Mobile App Development:</b> Engaging and functional mobile apps for multiple platforms.
                    </p>
                    <p>
                      <b>Progressive Web Apps:</b> Lightweight apps that combine the best of web and mobile experiences.
                    </p>
                    <p>
                      <b>Cloud-Based Solutions:</b> Scalable cloud apps to ensure smooth operation and high performance.
                    </p>
                    <p>
                      <b>Cross-Platform Development:</b> Unified apps for multiple devices using frameworks like React Native.
                    </p>
                    <p>
                      <b>API Integration:</b> Seamless third-party integrations for enhanced functionality.
                    </p>
                  </div>
                </div>
                <div className="col-span-6 overflow-hidden">
                  <div className="w-full h-full bg-cover bg-no-repeat bg-center transform transition-transform duration-300 hover:scale-110" style={{ backgroundImage: "url('/assets/images/design-bg.svg')" }} />
                  {/* <img src='/assets/images/design-bg.svg' className="" alt="Reactjs" /> */}
                </div>
              </div>
            </div>
            {/* Section 5 */}
            <div className="col-span-6 border border-[#2C2421]">
              <div className="grid grid-cols-12">
                <div className="col-span-12 overflow-hidden">
                  <img src='/assets/images/web-design.svg' className="w-full h-full bg-cover bg-no-repeat bg-center transform transition-transform duration-300 hover:scale-110" alt="Reactjs" />
                </div>
                <div className="col-span-12 px-[40px] py-[40px]">
                  <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px]">Website Design & Development Services</h3>
                  <p className="text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[17px]">Your website is your digital front door. We create responsive, user-friendly, and visually stunning websites tailored to showcase your brand and drive results.</p>
                  <div className="grid grid-cols-1 gap-[20px] sm:grid-cols-2 gap-y-[40px] text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[30px]">
                    <p>
                      <b>Responsive Website Design:</b> Designs optimized for all devices, ensuring seamless user experience.
                    </p>
                    <p>
                      <b>Headless CMS Integration:</b> Leveraging platforms like Strapi, Medusa JS, Contentful, and Storyblok.
                    </p>
                    <p>
                      <b>Custom WordPress Websites:</b> Developing tailored WordPress solutions with intuitive design and functionality.
                    </p>
                    <p>
                      <b>WIX & Webflow Website Development:</b> Building fast and scalable websites using WIX and Webflow for businesses.
                    </p>
                    <p>
                      <b>Custom Shopify Stores:</b> Designing tailored Shopify stores that enhance product visibility and user experience.
                    </p>
                    <p>
                      <b>Custom Development:</b> Creating unique web solutions designed to address specific business needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Section 6 */}
            <div className="col-span-6 border border-[#2C2421]">
              <div className="grid grid-cols-12 flex-col justify-between h-full">
                <div className="col-span-12 px-[40px] py-[40px]">
                  <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px]">Branding Services</h3>
                  <p className="text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[17px]">Your brand is your story and first impression. We craft memorable identities that resonate with your audience and reflect your values.</p>
                  <div className="grid grid-cols-1 gap-[20px] sm:grid-cols-2 gap-y-[40px] text-[14px] text-[#2C2421] font-medium font-instrument leading-[27px] mt-[30px]">
                    <p>
                      <b>Logo Design:</b> Creating unique and impactful logos that embody your brand.
                    </p>
                    <p>
                      <b>Visual Identity Creation:</b> Designing  visual elements to define your brand.
                    </p>
                    <p>
                      <b>Brand Guidelines Development:</b> Establishing rules for consistent branding across all platforms.
                    </p>
                    <p>
                      <b>Market Research:</b> Developing reusable design components for consistency and scalability.
                    </p>
                    <p>
                      <b>Packaging & Collateral Design:</b> Crafting designs for print and digital materials.
                    </p>
                    <p>
                      <b>Rebranding Services:</b> Revitalizing existing brands to align with new goals.
                    </p>
                  </div>
                </div>
                <div className="col-span-12 flex items-end overflow-hidden">
                  <img src='/assets/images/branding-bg.svg' className="w-full h-full bg-cover bg-no-repeat bg-center transform transition-transform duration-300 hover:scale-110" alt="Reactjs" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ServicePage;
