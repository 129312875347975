import * as motion from "motion/react-client";

// interface AnimationProps {
//     children: React.ReactNode;
//     duration?: number;
//     distance?: number;
//     tag?: keyof JSX.IntrinsicElements;
//     className?: string;
//     direction?: "up" | "down"; // Direction prop
// }

export const FadeIn = ({
    children,
    duration = 0.6,
    distance = 50,
    tag = "div",
    className = "",
    direction = "up",
    delay = 0,
}) => {
    const MotionTag = motion[tag];
    const translateY = direction === "up" ? distance : -distance;

    return (
        <MotionTag
            initial={{ opacity: 0, translateY }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{
                duration,
                ease: "easeOut",
                delay,
            }}
            className={className}
        >
            {children}
        </MotionTag>
    );
};

export const SlideIn = ({
    children,
    duration = 0.6,
    distance = 50,
    tag = "div",
    className = "",
    direction = "up",
    delay = 0,
}) => {
    const MotionTag = motion[tag];
    const translateY = direction === "up" ? distance : -distance;

    return (
        <MotionTag
            initial={{ opacity: 0, translateY }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{
                duration,
                ease: "easeOut",
                delay,
            }}
            className={className}
        >
            {children}
        </MotionTag>
    );
};

