import React from "react";
import PropTypes from "prop-types";

const Button = ({ type = "filled", className = "",outlineClasses="", children, ...props }) => {
  const baseClasses = "text-[16px] font-bold font-instrument py-[18px] px-[35px] mt-[5px] transition-all relative";
  const filledClasses =
    "bg-[#FFD600] text-[#2C2421] hover:bg-[#FFD600] hover:[box-shadow:8px_8px_0_rgba(211,184,45,0.48)]";
  const outlineClassesDefault =
    "bg-transparent text-[#2C2421] hover:bg-[#FFD600] hover:border-[#FFD600] hover:text-black hover:[box-shadow:8px_8px_0_rgba(211,184,45,0.48)]";

  const buttonClasses =
    type === "filled"
      ? `${baseClasses} ${filledClasses}`
      : `${baseClasses} ${outlineClassesDefault} ${outlineClasses} px-[50px]`;

  return (
    <button className={`${buttonClasses} ${className}`} {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(["filled", "outline"]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  outlineClasses:PropTypes.string,
};

export default Button;
