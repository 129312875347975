import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route } from 'react-router-dom'
import Slider from "react-slick";
import ShuffleTextEffect from '../../components/text-ani';
import Button from '../../components/ui/button';
import CampaignSliderCard from '../../components/ui/campaign-slider';
import ContactUsForm from '../../components/contact-form';
import { Helmet } from 'react-helmet';


import * as contentful from "contentful";

const client = contentful.createClient({
  space: "nkn87k8m8gkf",
  accessToken: "vSsPpEv7C5sTLVDCt26vqtf4_jh-GbHs2hFLMhI5IPk", 
});



const UIUXDesignService = () => {
    const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
};

const formRef = useRef(null);


const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.getEntries(); 
        console.log('ContentFull',response.items)
        setData(response.items);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;




   



    return (
        <div className="w-full mb-[40px]">
            <Helmet>
                <title>UI/UX Design Services – Stunning, User-Friendly Designs | StudioQ</title>
            </Helmet>

            <div className="container">
                <div className="grid gap-[50px] gap-y-[35px] grid-cols-12">
                    <div className="col-span-5">
                        <h1 className="font-regular  text-[33px] text-black leading-tight">
                            Designing UI/UX for Seamless<br />
                            <ShuffleTextEffect className="text-black text-[39px] font-[Nyght] font-extrabold italic relative bg-transparent !p-0" text="Digital Experiences" />
                        </h1>
                        <p className="mt-[18px] text-[18px] text-[#2C2421] font-light font-montserrat mb-[20px]">
                            Your users deserve intuitive, engaging, and visually stunning experiences. We design seamless UI/UX solutions that elevate your product and drive business success.
                        </p>
                        <div className='flex items-center gap-[25px]'>
                            <Button type="filled" onClick={scrollToForm}>
                                Get a Free Consultation
                            </Button>
                            <img
                                src="/assets/images/years.svg"
                                className="h-[93px] w-[106px]"
                                alt="UI UX"
                            />
                        </div>
                        <div className='w-full mt-[45px]'>
                            <h3 className="text-[27px] inline-block leading-[25px] mb-4 text-[#2C2421] border-l-6 border-[#FFD600] pl-[5px]">Our UI/UX Design Services Include:</h3>
                            <div className='w-full mt-[20px]  inline-block'>
                                <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                    ✔ Website & Landing Page Design
                                </div>
                                <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                    Engaging, high-performing designs that keep users hooked.
                                </div>
                            </div>
                            <div className='w-full mt-[20px]  inline-block'>
                                <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                    ✔ Mobile App UI/UX
                                </div>
                                <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                    Flawless designs optimized for an intuitive mobile experience.
                                </div>
                            </div>
                            <div className='w-full mt-[20px]  inline-block'>
                                <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                    ✔ SaaS & Web App Interfaces
                                </div>
                                <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                    Clean, efficient, and high-converting designs for applications.
                                </div>
                            </div>
                            <div className='w-full mt-[20px]  inline-block'>
                                <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                    ✔ Wireframing & Prototyping
                                </div>
                                <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                    Get a clear vision of your project before development.
                                </div>
                            </div>
                            <div className='w-full mt-[20px]  inline-block'>
                                <div className='w-full text-[#2C2421] text-[14px] font-bold font-instrument leading-[23px]'>
                                    ✔ Redesign & Optimization
                                </div>
                                <div className='w-full text-[#2C2421] text-[14px] font-instrument leading-[23px]'>
                                    Refresh outdated designs to enhance user experience and business impact.
                                </div>
                            </div>
                        </div>
                        <h3 className="text-[27px] leading-[25px] font-regular text-[#2C2421] border-l-[6px] border-[#FFD600] pl-[9px] mt-[60px]">Our Works</h3>
                        <div className='w-full'>
                            <Slider {...settings} className="clientSlider">
                                {/* Slider 1 */}
                                <div className="w-full">
                                    <CampaignSliderCard
                                        description="Part-Timer connects employers with part-time job seekers, making it easy to post jobs and find roles that fit skills and schedules."

                                        tags={["Product Design", "UI/UX", "App Design", "Web Design"]}
                                        sliderImage="/assets/images/slider-pt.svg"
                                    />
                                </div>
                                {/* Slider 2 */}
                                <div className="w-full">
                                    <CampaignSliderCard
                                        description="A custom WordPress site for Kalakshetra Foundation, showcasing their heritage and legacy with an engaging user experience."

                                        tags={['Product Design', 'UI/UX', 'App Design', 'Web Design']}
                                        sliderImage="/assets/images/kalakshetra-bg.svg"
                                    />
                                </div>
                                {/* Slider 3 */}
                                <div className="w-full">
                                    <CampaignSliderCard
                                        description="An innovative platform designed to empower aspiring and novice cooks by enabling them to learn directly from established chefs."

                                        tags={['Product Design', 'UI/UX', 'App Design']}
                                        sliderImage="/assets/images/slider-cook-along.svg"
                                    />
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="col-span-7">
                        <div className='w-full relative'>
                            <img
                                src="/assets/images/bg-logo.svg"
                                className="absolute top-0 left-0 right-0"
                                alt="UI UX"
                            />
                            <div className='w-full flex items-center gap-[40px]'>
                                <div className='relative items-end campain-card' onClick={scrollToForm}>

                                    <img
                                        src="/assets/images/ui-ux-1.svg"
                                        className=""
                                        alt="UI UX"
                                    />
                                    <div className='flex items-center justify-between gap-4 absolute bottom-[20px] left-[20px] right-[20px]'>
                                        <div className='text-[#2C2421] text-[16px] font-semibold font-instrument leading-[23px]'>
                                            Start Your Mobile App Design Today!
                                        </div>
                                        <button className='contents'>
                                            <img
                                                src="/assets/images/next.svg"
                                                className='w-[35px] h-[35px]'
                                                alt="UI UX"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className='relative items-end campain-card'  onClick={scrollToForm}>

                                    <img
                                        src="/assets/images/ui-ux-2.svg"
                                        className=""
                                        alt="Product Icon"
                                    />
                                    <div className='flex items-center justify-between gap-4 absolute bottom-[20px] left-[20px] right-[20px]'>
                                        <div className='text-[#2C2421] text-[16px] font-semibold font-instrument leading-[23px]'>
                                            Revamp Your Website with a Stunning Redesign!
                                        </div>
                                        <button className='contents'>
                                            <img
                                                src="/assets/images/next.svg"
                                                className='w-[35px] h-[35px]'
                                                alt="UI UX"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div ref={formRef} className='w-full mt-[130px]'>
                                <ContactUsForm formDescClass="hidden" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UIUXDesignService;