import React from "react";

const Tag = ({ texts }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {texts.map((text) => (
        <span
          key={text}
          className="border border-[#2C2421] text-[14px] font-instrument text-[#2C2421] px-4 h-[33px] flex items-center"
        >
          {text}
        </span>
      ))}
    </div>
  );
};

export default Tag;

