import React from "react";
import PropTypes from "prop-types";
import Tag from "./tags";

const PortfolioCard = ({ backgroundImage, logoSrc, description, roles, results, tags, sliderImage,sliderImageClass,cardClass }) => {
  return (
    <div className={` ${cardClass} pt-[95px] overflow-hidden`}>
      <div
        className="mx-auto px-[50px] py-[30px] min-h-[496px] bg-cover bg-no-repeat bg-left-center"
        style={{ backgroundImage: `url('${backgroundImage}')` }}
      >
        <div className="grid gap-[50px] grid-cols-12 items-center relative">
          {/* Left Section */}
          <div className="col-span-5">
            <div className="w-full">
              <img src={logoSrc} className="mb-[22px]" alt="Product Icon" />
            </div>
            <p className="text-[14px] leading-[24px] text-[#000000] font-light font-instrument mb-[30px]">
              {description}
            </p>
            <h2 className="text-[14px] leading-[24px] text-[#000000] font-bold font-instrument mb-[5px]">Our Role:</h2>
            <ul className="list-disc pl-5 text-[14px] leading-[24px] text-[#000000] font-light font-instrument mb-[30px]">
              {roles.map((role, index) => (
                <li key={index}>{role}</li>
              ))}
            </ul>
            <h2 className="text-[14px] leading-[24px] text-[#000000] font-bold font-instrument mb-[5px]">Results:</h2>
            <ul className="list-disc pl-5 text-[14px] leading-[24px] text-[#000000] font-light font-instrument mb-[30px]">
              {results.map((result, index) => (
                <li key={index}>{result}</li>
              ))}
            </ul>
            <div className="w-full pt-[10px] pb-[5px]">
              <Tag texts={tags} />
            </div>
          </div>
          {/* Right Section */}
          <img
            src={sliderImage}
            className={` ${sliderImageClass} absolute top-[-113px] min-h-[600px] right-[-110px]`}
            alt="Product Icon"
          />
        </div>
      </div>
    </div>
  );
};

PortfolioCard.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  results: PropTypes.arrayOf(PropTypes.string).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  sliderImage: PropTypes.string.isRequired,
  sliderImageClass: PropTypes.string,
  cardClass: PropTypes.string,
};

export default PortfolioCard;
